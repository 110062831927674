// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-selector-mobile {
  display: none;
  margin-left: auto;
}
@media (max-width: 599px) {
  .language-selector-mobile {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Home/sections/Navbar.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,iBAAA;AACD;AACC;EAJD;IAKE,cAAA;EAEA;AACF","sourcesContent":[".language-selector-mobile {\n\tdisplay: none;\n\tmargin-left: auto;\n\n\t@media (max-width: 599px) {\n\t\tdisplay: block;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
