// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: "Poppins", ui-monospace, Menlo, Monaco, "Cascadia Mono",
  	"Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace",
  	"Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New",
  	monospace;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

.homepage * {
  font-family: Poppins !important;
}

.custom-btn:hover {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.scss"],"names":[],"mappings":"AAEA;EACC,mBAAA;EACA,qBAAA;EACA;;;YAAA;AAGD;;AAGA;EACC,sBAAA;EACA,UAAA;EACA,SAAA;AAAD;;AAGA;;EAEC,gBAAA;EACA,kBAAA;AAAD;;AAGA;EACC,iCAAA;EACA,oHAAA;AAAD;;AASC;EACC,+BAAA;AANF;;AAWC;EACC,WAAA;AARF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap\");\r\n\r\n:root {\r\n\t--max-width: 1100px;\r\n\t--border-radius: 12px;\r\n\t--font-mono: \"Poppins\", ui-monospace, Menlo, Monaco, \"Cascadia Mono\",\r\n\t\t\"Segoe UI Mono\", \"Roboto Mono\", \"Oxygen Mono\", \"Ubuntu Monospace\",\r\n\t\t\"Source Code Pro\", \"Fira Mono\", \"Droid Sans Mono\", \"Courier New\",\r\n\t\tmonospace;\r\n}\r\n\r\n* {\r\n\tbox-sizing: border-box;\r\n\tpadding: 0;\r\n\tmargin: 0;\r\n}\r\n\r\nhtml,\r\nbody {\r\n\tmax-width: 100vw;\r\n\toverflow-x: hidden;\r\n}\r\n\r\nbody {\r\n\tcolor: rgb(var(--foreground-rgb));\r\n\tbackground: linear-gradient(\r\n\t\t\tto bottom,\r\n\t\t\ttransparent,\r\n\t\t\trgb(var(--background-end-rgb))\r\n\t\t)\r\n\t\trgb(var(--background-start-rgb));\r\n}\r\n\r\n.homepage {\r\n\t* {\r\n\t\tfont-family: Poppins !important;\r\n\t}\r\n}\r\n\r\n.custom-btn {\r\n\t&:hover {\r\n\t\tcolor: #000;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
