import { useEffect, useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import QrReader from "modern-react-qr-reader";
import { Outlet, useNavigate } from "react-router";
import secureLocalStorage from "react-secure-storage";
import { requestProvider } from "../../services/request.service";
import { BACKEND_ROUTES } from "../../utils/routes";
import { HTTP_STATUS_CODES } from "../../utils/enums";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";

const AdminLayout = () => {
	const navigator = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [loggedIn, setLoggedIn] = useState(false);
	const [scaningError, setScaningError] = useState("");
	const [cameraMode, setCameraMode] = useState("environment");
	const [expanded, setExpanded] = useState(false);

	const handleToggle = () => {
		setExpanded(!expanded);
	};

	const handleCloseModal = () => {
		setExpanded(false);
	};

	const handleClose = () => setExpanded(false);

	useEffect(() => {
		const permis = secureLocalStorage.getItem(
			process.env.REACT_APP_ACCESS ?? ""
		);

		if (permis === null) {
			navigator("/");
		}

		requestProvider(
			BACKEND_ROUTES.CONNECTED_ADMIN,
			"GET",
			{},
			{
				Authorization: `Bearer ${permis}`,
			}
		)
			.then((response) => {
				if (response.data.statusCode === HTTP_STATUS_CODES.OK) {
					setLoggedIn(true);
				} else {
					navigator("/");
				}
			})
			.catch((error) => {
				navigator("/");
			});
	}, []);

	const handleScan = (data: any) => {
		if (data) {
			if (data.includes("wecodeqr.com")) {
				setOpenModal(!openModal);
				handleCloseModal();

				let code = data.split("/").pop() ?? "";

				if (code.length > 0 && code[code.length - 1] === '"') {
					code = code.slice(0, -1);
				}

				navigator(
					`/application-backdoor/code/${code}
					`
				);
			} else {
				setScaningError("Invalid QR Code");
			}
		}
	};

	const handleError = (err: any) => {};

	return (
		<div>
			{loggedIn && (
				<>
					<Navbar
						bg="dark"
						expand="md"
						className="bg-body-tertiary mb-3"
						data-bs-theme="dark"
						variant="dark"
						expanded={expanded}
						onToggle={handleToggle}
					>
						<Container>
							<Navbar.Brand
								style={{ cursor: "pointer" }}
								onClick={() => {
									navigator(
										"/application-backdoor/dashboard"
									);
								}}
							>
								WeCodeQR
							</Navbar.Brand>
							<Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" />
							<Navbar.Offcanvas
								id="offcanvasNavbar-expand-md"
								aria-labelledby="offcanvasNavbarLabel-expand-md"
								placement="end"
							>
								<Offcanvas.Header closeButton>
									<Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
										WeCodeQR
									</Offcanvas.Title>
								</Offcanvas.Header>
								<Offcanvas.Body>
									<Nav className="justify-content-end flex-grow-1 pe-3">
										<Nav.Link
											className="btn "
											onClick={() => {
												handleClose();
												navigator(
													"/application-backdoor/orders"
												);
											}}
										>
											Orders
										</Nav.Link>

										<Nav.Link
											className="btn "
											onClick={() => {
												handleClose();
												navigator(
													"/application-backdoor/users"
												);
											}}
										>
											Users
										</Nav.Link>

										<Nav.Link
											className="btn "
											onClick={() => {
												handleClose();
												navigator(
													"/application-backdoor/dashboard"
												);
											}}
										>
											Codes
										</Nav.Link>

										{/* <Nav.Link
											className="btn "
											onClick={() => {
												handleClose();
												navigator(
													"/application-backdoor/generate-codes"
												);
											}}
										>
											Generate Codes
										</Nav.Link> */}

										{/* <Nav.Link
											className="btn "
											onClick={() => {
												setOpenModal(!openModal);
											}}
										>
											Scan code
										</Nav.Link> */}

										{/* Gallery */}
										<Nav.Link
											className="btn "
											onClick={() => {
												navigator(
													"/application-backdoor/gallery"
												);
											}}
										>
											Gallery
										</Nav.Link>
									</Nav>
								</Offcanvas.Body>
							</Navbar.Offcanvas>
						</Container>
					</Navbar>
					{/* Modal for scaning QR code */}
					<Modal show={openModal} id="scanQR">
						<div className="modal-header">
							<h5 className="modal-title">Scan QR code</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() => {
									setOpenModal(!openModal);
								}}
							/>
						</div>
						<div className="modal-body">
							{/* button that switches between camera modes: user or environment */}
							<div className="form-check form-switch">
								<input
									className="form-check-input"
									type="checkbox"
									id="flexSwitchCheckDefault"
									onChange={() => {
										if (cameraMode === "environment") {
											setCameraMode("user");
										} else {
											setCameraMode("environment");
										}
									}}
								/>
								<label
									className="form-check-label"
									htmlFor="flexSwitchCheckDefault"
								>
									{cameraMode === "environment"
										? "User"
										: "Environment"}
								</label>
							</div>

							{scaningError !== "" && (
								<div className="alert alert-danger">
									{scaningError}
								</div>
							)}
							<QrReader
								onScan={handleScan}
								onError={handleError}
								delay={300}
								style={{ width: "100%" }}
								facingMode={cameraMode}
							/>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-bs-dismiss="modal"
								onClick={() => {
									setOpenModal(!openModal);
								}}
							>
								Close
							</button>
						</div>
					</Modal>

					<Outlet />
				</>
			)}
		</div>
	);
};

export default AdminLayout;
