import { Translation } from "../../utils/translate";
import Navbar from "../QRinfo/navbar";

const TermsAndConditions = () => {
	return Translation.GetLanguage() === "ro" ? (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>Termini si Conditii utilizare WcqApp </h1>
				<p>
					Va recomandam sa cititi integral prezentii Termeni inainte
					de a decide daca ii acceptati si continuati procesul de
					inregistrare.
					<br /> <br />
					Site-ul wecodeqr.com este operat de societatea S.C. WYSAPP
					SMARTWEAR S.R.L. , cu sediul in Judetul Ilfov, Oras
					Voluntari, Sos. Bucuresti Nord, nr. 10B, Corp B1, Sc. C, Et.
					3, AP. 388 inscrisa in Registrul Comertului cu numarul
					J23/1321/2023.
					<br />
					<br />
					Dacă în orice moment după inregistrarea in aplicatia WCQAPP
					decideți că nu mai sunteti de acord cu prezentii Termeni, va
					rugam sa dezinstalati aplicatia.
					<br /> <br />
					Totodata, daca nu mai sunteti de acord cu utilizarea datelor
					dumneavoastra cu caracter personal de catre operatorul S.C.
					WYSAPP SMARTWEAR S.R.L., ca urmare a folosirii aplicatiei ,
					aveti posibilitatea sa stergeti contul si sa va exercitati
					drepturile prevazute de Regulamentul 679/2016 privind
					protectia persoanelor fizice in ceea ce priveste prelucrarea
					datelor cu caracter personal si privind libera circulatie a
					acestor date, la adresa de e-mail clients@wecodeqr.com.
					<br /> <br />
					Termenii si conditiile de utilizare a aplicaţiei WcqApp ,
					aşa cum sunt prezentaţi în prezentul document, sunt şi vor
					fi guvernaţi şi interpretaţi în conformitate cu legea română
					si europeana in vigoare.
					<br /> <br />
					Termenii stabilesc modul in care puteti utiliza aplicatia
					WcqApp.
					<br /> <br />
					Prin inregistrarea in aplicatie confirmati ca aveti 16 ani
					impliniti si ca nu v-a fost interzis dreptul de a utiliza un
					serviciu de genul celui oferit de WCQAPP.
					<br /> <br />
				</p>
				<p>
					<strong>1. Ce este WcqApp?</strong>
				</p>
				<p>
					WCQAPP este o aplicatie informatica securizata, accesibila
					prin intermediul telefonului mobil cu conexiune la Internet,
					unde utilizatorii pot accesa , modifica si publica , imagini
					si text , link-uri catre diverse site-uri, accesibile prin
					scanarea unui cod QR afisat pe diverse obiecte de
					imbracaminte, comercializate de catre S.C. WYSAPP SMARTWEAR
					S.R.L. sau de catre reselleri , parteneri si clienti ai S.C.
					WYSAPP SMARTWEAR S.R.L..
				</p>

				<p>
					<strong>2. Cum accesezi WcqApp?</strong>
				</p>
				<p>
					<strong>
						2.1. Inregistrarea si identificarea utilizatorului
					</strong>
					<br /> <br />
					Pentru inregistrarea in aplicatia WCQAPP este necesar ca
					utilizatorul sa ofere urmatoarele informatii obligatorii:
					nume, prenume, numarul de telefon, adresa de
					e-mail.\nInregistrarea datelor unui card bancar valabil este
					optionala.
					<br /> <br />
					<strong>
						2.2 Autentificarea utilizatorului pentru accesarea si
						utilizarea WcqApp.
					</strong>
					<br /> <br />
					Aplicatia retine datele de autentificare aferente contului
					dumneavoastra. In acest sens, sunteți de acord și înțelegeți
					că dumneavoastra sunteți responsabil(ă) pentru păstrarea
					confidențialității datelor de conectare asociate utilizarii
					de catre dumneavoastra a WCQAPP și pentru luarea tuturor
					măsurilor rezonabile pentru a păstra confidențialitatea
					acestor informații.
					<br /> <br />
					Dacă utilizați aplicatia WcqApp avand un cont de tip Family
					sau Corporate, dumneavoastra sunteți de acord că toți
					angajații, membrii de familie și toate celelalte persoane
					carora le-ati furnizat datele de conectare vor fi autorizate
					sa acceseze aplicati si sa acceseze serviciile de transport
					prin intermediul aplicatiei.
					<br /> <br />
					Va obligati sa notificați WcqApp imediat, fără întârzieri
					nejustificate, atunci când sesizați utilizarea neautorizată
					a datelor dumneavoastra de conectare. De asemenea, sunteți
					de acord să notificați WcqApp imediat, fără întârzieri
					nejustificate, în același mod cu privire la orice altă
					încălcare a securității în legătură cu aplicatia despre care
					aveți cunoștință.
					<br /> <br />
					WcqApp poate suspenda utilizarea datelor de conectare sau a
					Contului dumneavoastra în cazurile în care suspectează
					compromiterea securității acestora sau existența unei
					utilizări neautorizate sau frauduloase precum si in cazurile
					in care datele publicate de catre dvs . prin intermediul
					aplicatiei WcqApp sunt de natura obscena , au carcter
					defaimator si/sau se refera la activitati ce contravin
					legilor in vigoare pe teritoriul Uniunii Europen.
					<br /> <br />
					WcqApp vă va informa în prealabil sau, dacă acest lucru nu
					este posibil, imediat după suspendarea utilizării datelor
					dumneavoastra de conectare sau a Contului, specificând
					motivele suspendării, cu excepția cazului în care furnizarea
					acestor informații ar putea compromite măsurile rezonabile
					de securitate sau ar contraveni legii.
					<br /> <br />
					WcqApp va reactiva Datele de conectare sau Contul
					dumneavoastra ori vă va pune la dispoziție date de conectare
					noi sau un Cont nou, în cel mai scurt timp posibil după
					eliminarea motivelor care au determinat suspendarea
					inițială.
					<br /> <br />
				</p>

				<p>
					<strong>3. Ce poti face in aplicatia WcqApp?</strong>
				</p>

				<p>
					Utilizatorul poate publica imagini si text , link uri catre
					alte site uri publicate pe internet, cu scopul de a le face
					vizibile , oricaror persoane interesate , prin scanarea de
					catre acestia a unor coduri QR aflate pe obiecte de
					imbracaminte comercializate de catre WYSAPP SMARTWEAR S.R.L.
					<br /> <br />
					Utilizatorul poate previzualiza datele publicate in contul
					sau si va fi instiintat de fiecare data cand acestea sunt
					vizualizate de catre altcineva.
					<br /> <br />
				</p>

				<p>
					<strong>4. Declarații și garanții</strong>
				</p>

				<p>
					La momentul înregistrării în vederea utilizării WcqApp
					declarați şi garantați următoarele:
					<ul>
						<li>
							că aveți dreptul, autoritatea şi capacitatea deplină
							de exercițiu în vederea accesării şi utilizării
							aplicaţiei;
						</li>
						<li>
							ca nu comunicati datele de conectare parola unei
							terte persoane, cu exceptia persoanelor autorizate
							in Contul Corporate sau Family, intrucat prin
							divulgarea acesteia va expuneti riscului de
							utilizare a contului si serviciilor de catre alte
							persoane. Sunteti exclusiv responsabil pentru
							mentinerea confidentialitatii si pastrarea in
							siguranta a datelor introduse in aplicatia WcqApp.
							Mai mult, sunteti responsabil pentru activitatile de
							orice natura ce au loc in contul dumneavoastra,
							precum si pentru imaginile, textul, link-urile
							publicate prin intermediul aplicatiei.
						</li>
						<li>
							va obligati sa ne anuntati imediat de orice
							utilizare neautorizata a contului dumneavoastra sau
							de orice incalcare a securitatii contului de care
							deveniti constient sau pe care in mod rezonabil ar
							trebui sa o suspectati. Cu toate acestea, indiferent
							de aceasta notificare, nu vom raspunde pentru
							pierderile pe care ati putea sa le suportati ca
							urmare a utilizarii neautorizate a contului
							dmneavoastra ( indiferent daca o astfel de utilizare
							se produce cu sau fara cunostinta dumneavoastra)
						</li>
						<li>
							ca nu veti falsifica sau oferi informatii eronate
							despre identitatea sau intentiile dumneavoastra in
							legatura cu orice ar avea legatura cu aplicatia
						</li>
						<li>
							ca nu veti transmite, distribui sau oferi orice
							informatii sau materiale care sunt in tot sau in
							parte ilegale, false, frauduloase sau inadecvate,
							ofensatoare sau care incalca ordinea publica si
							bunele moravuri
						</li>
						<li>
							ca nu veti incalca orice drept prevazut prin lege,
							drepturi de proprietate, drepturi intangibile,
							dreptul privind confidentialitatea sau datele cu
							caracter personal ale altora;
						</li>
						<li>
							ca nu veti utiliza continutul aplicatiei pentru a
							revinde, distribui sau folosi serviciile oferite
						</li>
						<li>nu veti distribui ofertele din aplicatia WcqApp</li>
					</ul>
				</p>

				<p>
					<strong>5. Încetarea utilizarii aplicatiei WcqApp</strong>
				</p>

				<p>
					Incetarea utilizarii WcqApp de catre Utilizator poate
					interveni in urmatoarele situatii:
					<ul>
						<li>
							la initiativa utilizatorului, prin dezinstalarea
							aplicatiei de pe dispozitivul dumneavoastra.
						</li>
						<li>
							din initiativa WcqApp, daca se constata ca
							utilizatorul nu respecta regulile unei conduite
							adecvate sau prevederile Termenilor si Conditiilor,
							care ar putea aduce prejudicii de orice natura
							companiei;
						</li>
					</ul>
				</p>

				<p>
					<strong>6. Proprietatea intelectuala</strong>
				</p>

				<p>
					Utilizatorul declara in mod expres ca a luat la cunostinta
					de faptul ca toata creatia grafica, continutul aplicatiei si
					materialele accesate sunt proprietatea WcqApp.
					<br /> <br />
					Fac exceptie informatiile, elementele grafice apartinand
					altor entitati, care sunt proprietatea partenerilor.
					<br /> <br />
					Utilizatorul accepta ca orice software folosit in legatura
					cu serviciile oferite in aplicatie contine informatii
					confidentiale si aflate sub drept de proprietate, fiind
					protejate de legile privind dreptul de proprietate
					intelectuala, industriala si alte legi aplicabile.
					<br /> <br />
					Utilizatorul se obliga sa nu reproduca, sa nu copieze,sa nu
					vanda sau sa exploateze serviciile, informatiile si/sau
					materialele audio-video la care are acces ca urmare a
					utilizarii acestei aplicatii, in parte sau complet.
					Utilizatorului ii este interzisa modificarea, copierea,
					inchirierea, cedarea, imprumutul, vanzarea, distributia sau
					crearea unor materiale derivate bazate pe serviciile sau
					programele folosite in cadrul aplicatiei.
				</p>

				<p>
					<strong>7. Prelucrarea datelor cu caracter personal</strong>
				</p>

				<p>
					Prezentele Termeni si Conditii se completeaza cu Politica de
					Confidentialitate WcqApp aflata pe site-ul www.wecodeqr.com
					si in cadrul Aplicatiei la sectiunea „Politica de
					Confidentialitate”.
					<br /> <br />
					Recomandăm Utilizatorului a consulta si analiza Politica de
					Confidentialitate WcqApp.
				</p>

				<p>
					<strong>8. Pentru ce nu suntem responsabili</strong>
				</p>

				<p>
					Vă daţi acceptul că aplicaţia nu va fi livrată fără erori
					sau în mod continuu, că unele imperfecţiuni nu vor fi
					corectate la momentul apariţiei lor. Utilizatorul aplicaţiei
					este singurul responsabil pentru utilizarea aplicaţiei.
					<br /> <br />
					De asemenea, Utilizatorii vor raspunde pentru orice daune,
					directe sau indirecte, aduse WcqApp cu intentie sau din
					culpa (neglijenta sau imprudenta) utilizatorului sau
					persoanelor pentru care acesta raspunde, inclusiv Utilizator
					titular al Contului sau ca urmare a folosirii frauduloase a
					aplicatiei WcqApp.
					<br /> <br />
					Utilizatorul a fost informat si accepta faptul ca WcqApp nu
					pot fi facuta raspunzatoare, in niciun caz, pentru daunele,
					directe sau indirecte, cauzate Utilizatorului de
					nefunctionarea sau functionarea necorespunzatoare a
					aplicatiei.
				</p>

				<p>
					<strong>9. Legea aplicabilă</strong>
				</p>

				<p>
					În cazul apariţiei unui conflict/neînţelegeri legată de
					utilizarea aplicaţiei, vom încerca soluţionarea conflictului
					respectiv pe cale amiabilă, negocierile în acest sens fiind
					guvernate de bună credinţă.
					<br /> <br />
					Toate disputele vor fi soluţionate în instanţele competente
					din Bucuresti, cu excepţia cazurilor în care legislaţia în
					vigoare specifică o altă jurisdicţie exclusiva pentru
					dumneavoastră.
				</p>

				<p>
					<strong>10. Asistenţă</strong>
				</p>

				<p>
					Dacă aveţi orice problem legata de functionarea WcqApp, ne
					puteţi contacta la adresa de email clients@wecodeqr.com
				</p>
			</div>
		</>
	) : (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>WcqApp Terms and Conditions of Use</h1>
				<p>
					We recommend that you read these Terms in their entirety
					before deciding whether to accept them and continue the
					registration process.
					<br /> <br />
					The website wecodeqr.com is operated by the company S.C.
					WYSAPP SMARTWEAR S.R.L., located in Ilfov County, Voluntari
					City, Bucuresti Nord Street, No. 10B, Building B1, Floor 3,
					Apartment 388, registered at Registrul Comertului under
					number J23/1321/2023.
					<br />
					<br />
					If at any time after registering in the WCQAPP application
					you decide that you no longer agree to these Terms, please
					uninstall the application.
					<br /> <br />
					Furthermore, if you no longer agree to the use of your
					personal data by the operator S.C. WYSAPP SMARTWEAR S.R.L.
					as a result of using the application, you have the
					possibility to delete your account and exercise your rights
					provided by Regulation 679/2016 regarding the protection of
					individuals with regard to the processing of personal data
					and the free movement of such data, at the email address
					clients@wecodeqr.com.
					<br /> <br />
					The Terms and Conditions of use of the WcqApp application,
					as presented in this document, are and will be governed and
					interpreted in accordance with the Romanian and European
					laws in force.
					<br /> <br />
					The Terms establish how you can use the WcqApp application.
					<br /> <br />
					By registering in the application, you confirm that you are
					at least 16 years old and that you have not been prohibited
					from using a service similar to that offered by WCQAPP.
					<br /> <br />
				</p>
				<p>
					<strong>1. What is WcqApp?</strong>
				</p>
				<p>
					WCQAPP is a secure computer application, accessible through
					a mobile phone with an internet connection, where users can
					access, modify, and publish images and text, as well as link
					to various websites by scanning a QR code displayed on
					various clothing items sold by WYSAPP SMARTWEAR S.R.L. or
					its resellers, partners, and customers.
				</p>

				<p>
					<strong>2. How to access WcqApp?</strong>
				</p>
				<p>
					<strong>2.1. Registration and user identification</strong>
					<br /> <br />
					For registration in the WCQAPP application, the user is
					required to provide the following mandatory information:
					first name, last name, phone number, and email address.
					Registering a valid bank card is optional.
					<br /> <br />
					<strong>
						2.2 The user authentication process for accessing and
						using WCQAPP.
					</strong>
					<br /> <br />
					The application stores the authentication data associated
					with your account. In this regard, you agree and understand
					that you are responsible for maintaining the confidentiality
					of the login information associated with your use of WCQAPP
					and for taking all reasonable measures to keep this
					information confidential.
					<br /> <br />
					If you are using the WCQAPP application with a Family or
					Corporate account, you agree that all employees, family
					members, and any other individuals to whom you have provided
					login credentials will be authorized to access the
					application and utilize its transportation services.
					<br /> <br />
					You are obligated to promptly notify WCQAPP, without undue
					delay, if you become aware of any unauthorized use of your
					login credentials. Additionally, you agree to immediately
					notify WCQAPP, without undue delay, using the same method,
					regarding any other security breaches related to the
					application that you become aware of.
					<br /> <br />
					WCQAPP has the right to suspend the use of your login
					credentials or your account if it suspects a security
					compromise or unauthorized or fraudulent use. Additionally,
					if the data you publish through the WCQAPP application is
					obscene in nature, defamatory, or relates to activities that
					violate the laws in force within the European Union, WCQAPP
					may also suspend your account.
					<br /> <br />
					WCQAPP will inform you in advance or, if not possible,
					immediately after suspending the use of your login
					credentials or account, specifying the reasons for the
					suspension. However, there may be cases where providing such
					information could compromise reasonable security measures or
					contravene the law. In such cases, WCQAPP may not be able to
					disclose the detailed reasons for the suspension.
					<br /> <br />
					WCQAPP will reactivate your login credentials or account or
					provide you with new login credentials or a new account as
					soon as possible after resolving the reasons that led to the
					initial suspension. The reactivation process will be
					initiated promptly once the issues causing the suspension
					have been resolved.
					<br /> <br />
				</p>

				<p>
					<strong>3. What can you do in the WcqApp?</strong>
				</p>

				<p>
					The user can publish images, text, and links to other
					websites published on the internet with the purpose of
					making them visible to interested individuals. This can be
					achieved by scanning QR codes placed on clothing items sold
					by WCQAPP S.R.L.
					<br /> <br />
					The user can preview the data published in their account and
					will be notified each time it is viewed by someone else.
					<br /> <br />
				</p>

				<p>
					<strong>4. Declarations and warranties</strong>
				</p>

				<p>
					At the time of registration for using WcqApp, you declare
					and warrant the following:
					<ul>
						<li>
							You have the right, authority, and full legal
							capacity to access and use the application.
						</li>
						<li>
							You will not disclose your login credentials or
							password to any third party, except for authorized
							individuals in the Corporate or Family Account.
							Disclosing such information puts your account and
							services at risk of being accessed by others. You
							are solely responsible for maintaining the
							confidentiality and security of the data entered
							into the WcqApp application. Furthermore, you are
							responsible for any activities and content that
							occur in your account, including images, text, and
							links published through the application.
						</li>
						<li>
							You agree to immediately notify us of any
							unauthorized use of your account or any breach of
							its security that you become aware of or reasonably
							suspect. However, regardless of such notification,
							we will not be liable for any losses you may incur
							due to unauthorized use of your account (whether
							with or without your knowledge).
						</li>
						<li>
							You will not falsify or provide false information
							about your identity or intentions related to the
							application.
						</li>
						<li>
							You will not transmit, distribute, or provide any
							information or materials that are illegal, false,
							fraudulent, inappropriate, offensive, or violate
							public order and good morals.
						</li>
						<li>
							You will not infringe any rights provided by law,
							property rights, intangible rights, privacy rights,
							or personal data of others.
						</li>
						<li>
							You will not use the content of the application for
							resale, distribution, or unauthorized use of the
							services offered.
						</li>
						<li>
							You will not distribute offers from the WcqApp
							application.
						</li>
					</ul>
				</p>

				<p>
					<strong>
						5. Termination of use of the WcqApp application.
					</strong>
				</p>

				<p>
					Termination of use of WcqApp by the User can occur in the
					following situations:
					<ul>
						<li>
							At the user&apos;s initiative, by uninstalling the
							application from their device.
						</li>
						<li>
							At WcqApp&apos;s initiative, if it is found that the
							user does not comply with the rules of proper
							conduct or the provisions of the Terms and
							Conditions, which could cause harm to the company.
						</li>
					</ul>
				</p>

				<p>
					<strong>6. Intellectual property.</strong>
				</p>

				<p>
					The user expressly declares that they have acknowledged that
					all graphic creations, application content, and accessed
					materials are the property of WcqApp.
					<br /> <br />
					Exceptions are made for information and graphic elements
					belonging to other entities, which are the property of
					partners.
					<br /> <br />
					The user acknowledges that any software used in connection
					with the services offered in the application contains
					confidential and proprietary information, protected by laws
					regarding intellectual property rights, industrial property,
					and other applicable laws.
					<br /> <br />
					The user agrees not to reproduce, copy, sell, or exploit the
					services, information, and/or audio-video materials accessed
					as a result of using this application, in whole or in part.
					The user is prohibited from modifying, copying, renting,
					transferring, lending, selling, distributing, or creating
					derivative materials based on the services or programs used
					within the application.
				</p>

				<p>
					<strong>7. Processing of personal data.</strong>
				</p>

				<p>
					These Terms and Conditions are complemented by the WcqApp
					Privacy Policy, which can be found on the website
					www.wecodeqr.com and within the Application under the
					&quot;Privacy Policy&quot; section.
					<br /> <br />
					We recommend that the User consult and review the WcqApp
					Privacy Policy.
				</p>

				<p>
					<strong>8. We are not responsible for:</strong>
				</p>

				<p>
					You agree that the application will not be delivered without
					errors or continuously, and that some imperfections may not
					be corrected at the time of their occurrence. The user of
					the application is solely responsible for its use.
					<br /> <br />
					Additionally, users will be held liable for any damages,
					direct or indirect, caused intentionally or negligently (due
					to negligence or recklessness) by the user or persons for
					whom the user is responsible, including the user as the
					account holder, as a result of fraudulent use of the WcqApp
					application.
					<br /> <br />
					The user has been informed and accepts that WcqApp cannot be
					held responsible, under any circumstances, for any damages,
					direct or indirect, caused to the user due to the
					malfunction or improper functioning of the application.
				</p>

				<p>
					<strong>9. Applicable law</strong>
				</p>

				<p>
					In case of any disputes or disagreements related to the use
					of the application, we will attempt to resolve the conflict
					amicably through negotiations governed by good faith.
					<br /> <br />
					All disputes will be settled in the competent courts of
					Bucharest, except in cases where the applicable law
					specifies another exclusive jurisdiction for you.
				</p>

				<p>
					<strong>10. Support</strong>
				</p>

				<p>
					If you have any issues regarding the functioning of WcqApp,
					you can contact us at the email address
					clients@wecodeqr.com.
				</p>
			</div>
		</>
	);
};

export default TermsAndConditions;
