// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer .container {
  border-top: 1px solid #c7cdd8;
  padding-top: 30px;
}
footer .container .copyright {
  text-decoration: none;
  color: #000;
}
footer .container .row {
  justify-content: space-between;
}
footer .container .links a {
  color: #000;
  text-decoration: none;
}

.navbar-custom-container {
  background: linear-gradient(74.43deg, #147cf3 0%, #5ba4f2 100%);
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/MainLayout.scss"],"names":[],"mappings":"AACC;EACC,6BAAA;EACA,iBAAA;AAAF;AAEE;EACC,qBAAA;EACA,WAAA;AAAH;AAGE;EACC,8BAAA;AADH;AAKG;EACC,WAAA;EACA,qBAAA;AAHJ;;AASA;EACC,+DAAA;AAND","sourcesContent":["footer {\r\n\t.container {\r\n\t\tborder-top: 1px solid #c7cdd8;\r\n\t\tpadding-top: 30px;\r\n\r\n\t\t.copyright {\r\n\t\t\ttext-decoration: none;\r\n\t\t\tcolor: #000;\r\n\t\t}\r\n\r\n\t\t.row {\r\n\t\t\tjustify-content: space-between;\r\n\t\t}\r\n\r\n\t\t.links {\r\n\t\t\ta {\r\n\t\t\t\tcolor: #000;\r\n\t\t\t\ttext-decoration: none;\r\n\t\t\t}\r\n\t\t}\r\n\t}\r\n}\r\n\r\n.navbar-custom-container {\r\n\tbackground: linear-gradient(74.43deg, #147cf3 0%, #5ba4f2 100%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
