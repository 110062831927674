// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guide_guide_section__37pt3 {
  max-width: 100vw;
  padding: 0;
}

.guide_guide_section_box__p4wlU {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 70rem;
  padding: 2rem 0;
  margin: 0 auto;
  background-color: #fff;
}

.guide_step_box__QoCJf {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 65rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/sections/guide.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,sBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ","sourcesContent":[".guide_section{\r\n    max-width: 100vw;\r\n    padding: 0;\r\n}\r\n\r\n.guide_section_box {\r\n    position: relative;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    max-width: 70rem;\r\n    padding: 2rem 0;\r\n    margin: 0 auto;\r\n    background-color: #fff;\r\n}\r\n\r\n.step_box {\r\n    position: absolute;\r\n    margin: auto;\r\n    left: 0;\r\n    right: 0;\r\n    top: 65rem;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guide_section": `guide_guide_section__37pt3`,
	"guide_section_box": `guide_guide_section_box__p4wlU`,
	"step_box": `guide_step_box__QoCJf`
};
export default ___CSS_LOADER_EXPORT___;
