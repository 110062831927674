import {
	Box,
	styled,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Link,
} from "@mui/material";
import { Container } from "@mui/system";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";

import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useState } from "react";
import { scroller } from "react-scroll";
import { Translation } from "../../../utils/translate";
import "./Navbar.scss";

export default function Navbar() {
	const [mobileMenu, setMobileMenu] = useState({
		left: false,
	});

	const toggleDrawer =
		(anchor: string, open: boolean) => (event: { type: string }) => {
			setMobileMenu({ ...mobileMenu, [anchor]: open });
		};

	const list = (anchor: string) => (
		<Box
			sx={{
				width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
			}}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List>
				{[
					{ title: "Home", href: "#home-section" },
					{ title: "How it works", href: "#guide-section" },
					{ title: "About us", href: "#about-section" },
					{ title: "FAQ’s", href: "#faq-section" },
					{ title: "Download", href: "#download-section" },
				].map((obj, index) => (
					<ListItem key={obj.title} disablePadding>
						<ListItemButton href={obj.href}>
							<ListItemText primary={obj.title} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);

	const NavLink = styled(Link)(({ theme }) => ({
		fontSize: "16px",
		color: "#fff",
		fontWeight: "400",
		cursor: "pointer",
		textDecoration: "none",
		"&:hover": {
			color: "#fff",
		},

		[theme.breakpoints.down("md")]: {
			fontSize: "14px",
		},
	}));

	const NavbarLinksBox = styled(Box)(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: theme.spacing(6),
		[theme.breakpoints.down("md")]: {
			gap: theme.spacing(2),
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	}));

	const BurgerMenu = styled(Box)(({ theme }) => ({
		alignItems: "center",
		display: "none",

		[theme.breakpoints.down("sm")]: {
			display: "flex",
		},
	}));

	const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
		cursor: "pointer",
		display: "none",
		color: "#fff",
		fontSize: "36px",
		marginRight: theme.spacing(2),
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
	}));

	const NavbarContainer = styled(Container)(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		gap: "1rem",
	}));

	const NavbarLogo = styled("img")(({ theme }) => ({
		cursor: "pointer",
		[theme.breakpoints.down("md")]: {
			width: "4rem",
		},
	}));

	const SocialMediaBox = styled(Box)(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: "0",

		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	}));

	const CustomLanguageIcon = styled("img")(({ theme }) => ({
		height: "24px",
	}));

	return (
		<NavbarContainer className="navbar">
			<Box>
				<NavbarLogo src="logo_white.png" sx={{ width: '60px' }} alt="logo" />
			</Box>

			<div className="language-selector-mobile">
				{Translation.GetLanguage() === "ro" ? (
					<IconButton
						sx={{ color: "#fff" }}
						onClick={() => Translation.SetLanguage("en")}
					>
						<LanguageIcon />
					</IconButton>
				) : (
					<IconButton
						sx={{ color: "#fff" }}
						onClick={() => Translation.SetLanguage("ro")}
					>
						<CustomLanguageIcon src="ro_icon.png" />
					</IconButton>
				)}
			</div>

			<BurgerMenu>
				<CustomMenuIcon onClick={toggleDrawer("left", true)} />
				<Drawer
					anchor="left"
					open={mobileMenu["left"]}
					onClose={toggleDrawer("left", false)}
				>
					{list("left")}
				</Drawer>
			</BurgerMenu>

			<NavbarLinksBox>
				<NavLink
					onClick={() => {
						const element = document.getElementById("home-section");

						element?.scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
						});
					}}
					variant="body2"
				>
					{Translation.Translate("navbar", "home")}
				</NavLink>
				<NavLink
					onClick={() => {
						const element =
							document.getElementById("guide-section");

						element?.scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
						});
					}}
					variant="body2"
				>
					{Translation.Translate("navbar", "howitworks")}
				</NavLink>
				<NavLink
					onClick={() => {
						const element =
							document.getElementById("about-section");

						element?.scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
						});
					}}
					variant="body2"
				>
					{Translation.Translate("navbar", "about")}
				</NavLink>
				<NavLink
					onClick={() => {
						const element = document.getElementById("faq-section");

						element?.scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
						});
					}}
					variant="body2"
				>
					{Translation.Translate("navbar", "faq")}
				</NavLink>
				<NavLink
					onClick={() => {
						const element =
							document.getElementById("download-section");

						element?.scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
						});
					}}
					variant="body2"
				>
					{Translation.Translate("navbar", "download")}
				</NavLink>
			</NavbarLinksBox>

			<SocialMediaBox>
				<IconButton
					href="https://www.facebook.com/wearyourspaceapp/"
					target="_blank"
					sx={{ color: "#fff" }}
				>
					<FacebookRoundedIcon />
				</IconButton>
				<IconButton
					href="https://www.instagram.com/wearyourspace/"
					target="_blank"
					sx={{ color: "#fff" }}
				>
					<InstagramIcon />
				</IconButton>

				{Translation.GetLanguage() === "ro" ? (
					<IconButton
						sx={{ color: "#fff" }}
						onClick={() => Translation.SetLanguage("en")}
					>
						<LanguageIcon />
					</IconButton>
				) : (
					<IconButton
						sx={{ color: "#fff" }}
						onClick={() => Translation.SetLanguage("ro")}
					>
						<CustomLanguageIcon src="ro_icon.png" />
					</IconButton>
				)}
			</SocialMediaBox>
		</NavbarContainer>
	);
}
