'use client';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Box, Button, Stack, styled } from '@mui/material';
import { Container } from 'reactstrap';

import styles from './about.module.sass';
import { Translation } from '../../../utils/translate';
import './About.scss';

export default function About() {
  const theme = useTheme();
  const onMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(({ theme }) => ({
    maxWidth: '1200px',
    padding: '4rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.down('sm')]: {
      padding: '2rem 0',
    },
  }));

  const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      gap: '0',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: '32px',
    color: '#282828',
    fontWeight: '800',

    margin: '0 0 4rem 0',

    [theme.breakpoints.down('md')]: {
      margin: '0 0 1rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  }));

  const TextBox = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'baseline',
    marginTop: '1rem',
    padding: '0 4rem',

    [theme.breakpoints.down(1200)]: {
      padding: '0 2rem',
    },

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 2rem',
      alignItems: 'center',
      maxWidth: '95vw',
      alignSelf: 'center',
    },
  }));

  const Desctiption = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.7rem',
    fontSize: '22px',
    color: '#595757',
    margin: '2rem 0',

    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '1.5rem',
      margin: '1rem 0',
    },
  }));

  const StackBox = styled(Box)(({ theme }) => ({
    flex: 1,

    [theme.breakpoints.down('md')]: {
      flex: '1.8',
    },
  }));

  const ImageStack = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      gap: '4px',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));

  const ImageItem = styled('img')(({ theme }) => ({
    height: 'inherit',

    [theme.breakpoints.down('md')]: {
      height: '8rem',
      maxHeight: '8rem',
    },

    [theme.breakpoints.down('sm')]: {
      height: '10rem',
      maxHeight: '10rem',
    },
  }));

  const CustomButton = styled(Button)(({ theme }) => ({
    background: '#E82A49',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    width: '12rem',
    height: '4rem',

    color: '#fff',
    fontWeight: '300',
    fontSize: '18px',
    textTransform: 'none',

    [theme.breakpoints.down('sm')]: {
      width: '14rem',
      marginTop: '3rem',
    },
  }));

  return (
    <Box
      id="about-section"
      sx={{
        background:
          'linear-gradient(77deg, rgba(20, 124, 243, 0.2) 0%, rgba(91, 164, 242, 0.2) 100%)',
      }}
    >
      <Container>
        <CustomBox>
          <Title>
            {Translation.Translate('about-us', 'title')}
          </Title>
          <ContentBox>
            <StackBox sx={{ flex: 1 }}>
              <ImageStack
                spacing={{ xs: 1, sm: 2 }}
                useFlexGap
                flexWrap="wrap"
              >
                <ImageItem
                  className={`${styles.about_image} about-image-short me-auto`}
                  src="about_us.png"
                />
                {/* <ImageItem
                  className={`${styles.about_image} about-image-short`}
                  src="white-back.jpg"
                /> */}

                {/* {!onMobileDevice && (
                  <ImageItem
                    className={`${styles.about_image} about-image-long`}
                    src="image_big.png"
                  />
                )} */}
              </ImageStack>
            </StackBox>
            <TextBox>
              <Desctiption>
                {Translation.Translate(
                  'about-us',
                  'description',
                )}
              </Desctiption>
              {/* <CustomButton
                  className="custom-btn"
                  href="https://www.shop.wearyourspace.com/"
                >
                  {Translation.Translate(
									  'about-us',
									  'shop-now',
                  )}
                </CustomButton> */}
            </TextBox>
          </ContentBox>
        </CustomBox>
      </Container>
    </Box>
  );
}
