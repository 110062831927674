import React, { useEffect } from "react";
import "../AdminInterface.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { requestProvider } from "../../../services/request.service";
import { BACKEND_ROUTES } from "../../../utils/routes";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { ITEMS_PER_PAGE } from "../../../utils/constants";
import Pagination from "@mui/material/Pagination";
import { FaDownload, FaEye, FaFileExport } from "react-icons/fa";
import { BiExport } from "react-icons/bi";
import { useNavigate } from "react-router";

import { Autocomplete, TextField } from "@mui/material";
import { ConstructionOutlined, QrCode } from "@mui/icons-material";
import { stat } from "fs";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import { Modal } from "react-bootstrap";


enum PageState {
	DASHBOARD,
	CODE_DETAILS,
}

interface StateDetails {
	codes: number;
	transactionData: any[];
	scanData: string;
	openModal: boolean;

	pageState: PageState;
	pageIndex: number;

	searchResults: Array<string>;
	codesToDisplay: any[];

	codesCSV: any[]; // CSV data to be downloaded
	codesCSVLink: any;
	qrCodeLink: any;
}

const Dashboard = function (props: any) {
	const [state, setState] = React.useState<StateDetails>({
		codes: 0,
		transactionData: [],
		scanData: "",
		openModal: false,
		pageState: PageState.DASHBOARD,
		pageIndex: 0,
		searchResults: [],
		codesToDisplay: [],
		codesCSV: [],
		codesCSVLink: React.createRef(),
		qrCodeLink: React.createRef(),
	});

	const [searchTerm, setSearchTerm] = React.useState("");
	const [timeoutId, setTimeoutId] = React.useState<any>(null);
	const [openModal, setOpenModal] = React.useState(false);

	const navigator = useNavigate();

	useEffect(() => {
		getCodes();
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		// Perform the search when the searchTerm changes
		performSearch();
	}, [searchTerm]);

	// Download the codes when the codesCSV changes
	React.useEffect(() => {
		if (state.codesCSVLink && state.transactionData.length > 0) {
			state.codesCSVLink.current.link.click();
		}
	}, [state.codesCSV]);

	const changePage = (event: any, page: number) => {
		setState({
			...state,
			pageIndex: page - 1,
		});
	};

	const performSearch = async () => {
		// Clear the previous timeout if any
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		if (searchTerm.length > 2) {
			// Set a new timeout to delay the search
			const newTimeoutId = setTimeout(() => {
				// Perform the actual search
				const codesToDisplay = getSearchResults(searchTerm);

				const searchResults = codesToDisplay.map((code) => {
					return code.value;
				});

				// Update the search results
				setState({
					...state,
					codesToDisplay: codesToDisplay,
					searchResults: searchResults,
				});
			}, 1000); // 1 second delay
		} else {
			setState({
				...state,
				searchResults: [],
				codesToDisplay: state.transactionData,
			});
		}

		// Store the timeoutId in state for later reference
		setTimeoutId(timeoutId);
	};

	const getSearchResults = (searchTerm: string) => {
		// Return the search results
		const codesToDisplay = state.transactionData.filter((transaction) => {
			return transaction.value.includes(searchTerm);
		});

		return codesToDisplay;
	};

	// get the codes from the backend
	const getCodes = async () => {
		requestProvider(
			BACKEND_ROUTES.ALL_CODES,
			"GET",
			{},
			{
				"Content-Type": "application/json",
				Authorization: `Bearer ${secureLocalStorage.getItem(
					process.env.REACT_APP_ACCESS ?? ""
				)}`,
			}
		).then((response) => {
			setState({
				...state,
				transactionData: response.data,
				codesToDisplay: response.data,
			});
		});
	};

	const deleteUnproducedCodes = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.DELETE_UNPRODUCED_CODES,
				"GET",
				{},
				{
					"Content-Type": "application/json",
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				}
			)
				.then((response) => {
					window.location.reload();
				})
				.catch((error) => {});
		} catch (error) {}
	};

	const exportAllCodes = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.EXPORT_CODES,
				"GET",
				{},
				{
					Authorization:
						"Bearer " +
						secureLocalStorage.getItem(
							process.env.REACT_APP_ACCESS ?? ""
						),
				}
			)
				.then((response) => {
					const data = Papa.parse(response.data, { delimiter: "\n" });

					setState({
						...state,
						codesCSV: data.data,
					});
				})
				.catch((error) => {
					setState({
						...state,
						codesCSV: [],
					});
				});
		} catch (error) {}
	};

	const downloadQrCode = async (codeValue: any) => {
		const codeUrl = process.env.REACT_APP_API_URL + "/" + codeValue;

		// eslint-disable-next-line @typescript-eslint/no-require-imports
		let QRCode = require('qrcode');

		QRCode.toCanvas(codeUrl, { errorCorrectionLevel: 'H' }, (err: any, canvas: any) => {
			if (err) throw err
			let link = document.createElement('a');

			state.qrCodeLink.current.href = canvas.toDataURL();
			state.qrCodeLink.current.download = codeValue + '.png';
			state.qrCodeLink.current.click();
		}
		);
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setSearchTerm(value);
	};

	return (
		// Sticky navbar with one button with text "Delete code"
		<div className="admin-interface">
			{/* Search bar */}
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="card mt-4">
							<div className="card-body">
								<button
									className="btn btn-primary"
									style={{ marginRight: "10px" }}
									onClick={() => {
										setOpenModal(!openModal);
									}}
								>
									Delete all unproduced codes
								</button>

								<button
									className="btn btn-primary"
									onClick={() => {
										exportAllCodes();
									}}
								>
									Export all codes
								</button>

								<CSVLink
									data={state.codesCSV}
									filename="data.csv"
									style={{ display: "none" }}
									ref={state.codesCSVLink}
									target="_blank"
								/>

								<a ref={state.qrCodeLink} style={{display: 'none'}}/>

								<Autocomplete
									id="search-bar"
									freeSolo
									autoComplete
									autoHighlight
									options={state.searchResults}
									renderInput={(params) => (
										<TextField
											{...params}
											onChange={handleInputChange}
											variant="outlined"
											label="Search Box"
										/>
									)}
									// Make options clickable and redirect to the code details page
									onChange={(event, value) => {
										if (value) {
											navigator(
												"/application-backdoor/code/" +
													value
											);
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* // A list of all the codes without form */}
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="card mt-4">
							<div className="card-body">
								<Table className="w-100">
									<Thead>
										<Tr>
											<Th>Nr.</Th>
											<Th>Value</Th>
											<Th>Status</Th>
											<Th>Actions</Th>
										</Tr>
									</Thead>

									<Tbody>
										{state.codesToDisplay

											.slice(
												state.pageIndex *
													ITEMS_PER_PAGE,
												(state.pageIndex + 1) *
													ITEMS_PER_PAGE
											)
											.map((transaction, index) => (
												<Tr key={index}>
													<Td>
														{state.pageIndex *
															ITEMS_PER_PAGE +
															index +
															1}
													</Td>
													<Td>{transaction.value}</Td>
													<Td>
														{transaction.status ??
															"-"}
													</Td>
													<Td>
														<button
															className="btn btn-primary"
															onClick={() => {
																navigator(
																	"/application-backdoor/code/" +
																		transaction.value
																);
															}}
														>
															<FaEye className="m-0 p-0" />
														</button>
														<button
															className="btn btn-primary ms-2"
															onClick={() => {
																downloadQrCode(transaction.value);
															}}
														>
															<FaDownload className="m-0 p-0" />
														</button>
													</Td>
												</Tr>
											))}
									</Tbody>
								</Table>

								<Modal show={openModal}>
									<div className="modal-header">
										<h5 className="modal-title">
											Scan QR code
										</h5>
										<button
											type="button"
											className="btn-close"
											data-bs-dismiss="modal"
											aria-label="Close"
											onClick={() => {
												setOpenModal(!openModal);
											}}
										/>
									</div>
									<div className="modal-body">
										<div className="row">
											<h4>
												Are you sure do you want to
												deleted all uproduced codes?
											</h4>
										</div>
									</div>
									<div className="modal-footer">
										<button
											type="button"
											className="btn btn-secondary"
											data-bs-dismiss="modal"
											onClick={() => {
												setOpenModal(!openModal);
											}}
										>
											Close
										</button>

										<button
											type="button"
											className="btn btn-danger"
											data-bs-dismiss="modal"
											onClick={(e: any) => {
												e.preventDefault();

												deleteUnproducedCodes();
											}}
										>
											Delete
										</button>
									</div>
								</Modal>

								{state.codesToDisplay.length >
									ITEMS_PER_PAGE && (
									<Pagination
										className="cards-pagination mt-5 align-items-center justify-content-center"
										count={
											Math.floor(
												state.codesToDisplay.length /
													ITEMS_PER_PAGE
											) + 1
										}
										variant="outlined"
										shape="rounded"
										onChange={changePage}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
