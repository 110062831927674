import { useEffect, useState } from "react";
import { requestProvider } from "../../../services/request.service";
import { BACKEND_ROUTES } from "../../../utils/routes";
import secureLocalStorage from "react-secure-storage";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { useNavigate } from "react-router";

const Orders = () => {
	const [orders, setOrders] = useState<any[]>([]);
	const navigator = useNavigate();

	useEffect(() => {
		getOrders();
	}, []);

	const getOrders = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.ALL_ORDERS,
				"GET",
				{},
				{
					"Content-Type": "application/json",
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					let finalOrders: any[] = [];

					if (response.status === 200) {
						// eslint-disable-next-line guard-for-in
						for (let order in response.data.data) {
							finalOrders.push({
								email: response.data.data[order].email,
								status: response.data.data[order].status,
								orderId: response.data.data[order]._id,
							});
						}
					}

					setOrders(finalOrders);
				})
				.catch((error) => {});
		} catch (error) {}
	};

	return (
		<div className="admin-interface">
			{/* Search bar */}

			{/* // A list of all the codes without form */}
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="card mt-4">
							<div className="card-body">
								<Table className="w-100">
									<Thead>
										<Tr>
											<Th>Nr.</Th>
											<Th>Email</Th>
											<Th>Status</Th>
											<Th>Actions</Th>
										</Tr>
									</Thead>

									<Tbody>
										{orders.map((order, index) => {
											return (
												<Tr key={index}>
													<Td>{index + 1}</Td>
													<Td>{order.email}</Td>
													<Td>{order.status}</Td>
													<Td>
														<button
															className="btn btn-primary"
															onClick={() => {
																navigator(
																	`/application-backdoor/orders/${order.orderId}`
																);
															}}
														>
															See
														</button>
													</Td>
												</Tr>
											);
										})}
									</Tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Orders;
