// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download_download_section__cP1EW {
  max-width: 1200px;
}

@media (min-width: 600px) {
  .download_button_download__P3AvC {
    margin-left: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Home/sections/download.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;;AAEC;EADD;IAEE,iBAAA;EAEA;AACF","sourcesContent":[".download_section {\r\n\tmax-width: 1200px;\r\n}\r\n.button_download {\r\n\t@media (min-width: 600px) {\r\n\t\tmargin-left: 20px;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"download_section": `download_download_section__cP1EW`,
	"button_download": `download_button_download__P3AvC`
};
export default ___CSS_LOADER_EXPORT___;
