import { Translation } from "../../utils/translate";
import Navbar from "../QRinfo/navbar";

const GDPR = () => {
	return Translation.GetLanguage() === "ro" ? (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>GDPR </h1>

				<p>
					<strong>I. Informații generale</strong>
				</p>
				<p>
					Site-ul wecodeqr.com este operat de societatea S.C. WYSAPP
					SMARTWEAR S.R.L. , cu sediul in Judetul Ilfov, Oras
					Voluntari, Sos. Bucuresti Nord, nr. 10B, Corp B1, Sc. C, Et.
					3, AP. 388 inscrisa in Registrul Comertului cu numarul
					J23/1321/2023.
					<br />
					<br />
					WYSAPP este o aplicatie informatica securizata, accesibila
					prin intermediul telefonului mobil cu conexiune la Internet,
					unde utilizatorii pot accesa, modifica si publica, imagini
					si text, link-uri catre diverse site-uri, accesibile prin
					scanarea unui cod QR afisat pe diverse obiecte de
					imbracaminte, comercializate de catre S.C. WYSAPP SMARTWEAR
					S.R.L. sau de catre reselleri, parteneri si clienti ai S.C.
					WYSAPP SMARTWEAR S.R.L..
				</p>

				<p>
					<strong>
						II. Categoriile de date cu caracter personal prelucrate
					</strong>
				</p>
				<p>
					<ul>
						<li>
							<strong>II.1. </strong> Dacă sunteți utilizator al
							Site-ului precum si al aplicatiei mobile WysApp,
							WysApp Srl va prelucra datele dumneavoastră cu
							caracter personal, cum ar fi nume şi prenume,
							telefon, adresa de e-mail, adresa de livrare, precum
							si datele publicate de catre dumneavoastra prin
							intermediul aplicatiei mobile (poze, imagini
							digitale, link-uri), date referitoare la modul în
							care utilizați Site-ul, de exemplu comportamentul /
							preferinţele /obişnuințele dumneavoastră în cadrul
							wecodeqr.com, precum și orice alte categorii de date
							pe care le furnizați în mod direct în contextul
							creării contului de utilizator, în contextul
							plasării unei comenzi prin intermediul site-ului sau
							în orice alt mod care rezultă din utilizarea
							Site-ului.
							<br /> <br />
							Dacă pentru a vă crea cont de utilizator pe Site sau
							in aplicatia mobila, utilizați contul dumneavoastră
							de Facebook sau Google, S.C. WYSAPP SMARTWEAR S.R.L.
							va prelucra următoarele date publice de profil
							afişate de aplicaţiile respective: nume utilizator,
							adresa de e-mail.
							<br /> <br />
							În cazul în care alegeți să vă creați cont de
							utilizator doar înainte de a finaliza comanda unui
							produs disponibil pe Site, se va solicita adresa
							dumneavoastră de e-mail în baza căreia va fi creat
							automat un cont. În cazul în care nu finalizați
							comanda, adresa de e-mail și celelalte date
							furnizate nu vor fi stocate de WysApp Srl, iar
							contul creat va fi șters automat.
						</li>
						<li>
							<strong>II.2.</strong> Dacă sunteți vizitator al
							Site-ului, S.C. WYSAPP SMARTWEAR S.R.L. va prelucra
							datele dumneavoastră cu caracter personal pe care le
							furnizați în mod direct în contextul utilizării
							Site-ului, cum ar fi datele pe care le furnizați în
							cadrul secțiunii de contact / întrebări /
							reclamații, în măsura în care ne contactați în acest
							fel.
						</li>
					</ul>
				</p>

				<p>
					<strong>III. Scopurile și temeiurile prelucrării</strong>
				</p>

				<p>
					<strong>III.1.</strong> Dacă sunteți client al Site-ului,
					WysApp Srl prelucrează datele dumneavoastră cu caracter
					personal astfel:
					<br /> <br />- pentru desfășurarea relației contractuale
					dintre dumneavoastră şi S.C. WYSAPP SMARTWEAR S.R.L.,
					respectiv pentru preluarea, validarea, expedierea şi
					facturarea comenzii plasate pe Site, informarea
					dumneavoastră asupra stării comenzii, organizarea returului
					de produse comandate etc.
					<br /> <br />
					Temei: Prelucrarea datelor dumneavoastră în acest scop are
					la bază contractul încheiat între dumneavoastră și S.C.
					WYSAPP SMARTWEAR S.R.L., definit în cuprinsul Termeni și
					Condiții utilizare WysApp . Furnizarea datelor dumneavoastră
					cu caracter personal este necesară pentru executareaa
					acestui contract. Refuzul furnizării datelor poate avea
					drept consecință imposibilitatea derulării raporturilor
					contractuale dintre dumneavoastră și S.C. WYSAPP SMARTWEAR
					S.R.L..
					<br /> <br />- pentru îndeplinirea obligațiilor legale care
					incumbă S.C. WYSAPP SMARTWEAR S.R.L. în contextul
					serviciilor prestate prin intermediul Site-ului, inclusiv a
					obligațiilor în materie fiscală, precum și în materie de
					arhivare.
					<br /> <br />
					Temei: Prelucrarea datelor dumneavoastră pentru acest scop
					este necesară în baza unor obligații legale. Furnizarea
					datelor dumneavoastră în acest scop este necesară. Refuzul
					furnizării datelor poate avea drept consecință
					imposibilitatea S.C. WYSAPP SMARTWEAR S.R.L. de a respecta
					obligațiile legale care îi revin și deci în imposibilitatea
					de a vă oferi serviciile prin intermediul Site-ului.
					<br /> <br />- pentru activităţi de marketing, respectiv
					pentru transmiterea, prin intermediul mijloacelor de
					comunicare la distanţă (e-mail, sms) de comunicări
					comerciale privind produsele şi serviciile oferite de S.C.
					WYSAPP SMARTWEAR S.R.L., prin intermediul Site-ului.
					<br /> <br />
					Temei: Prelucrarea datelor dumneavoastră pentru acest scop
					are la bază consimțământul dumneavoastră, dacă alegeți să-l
					furnizați.
					<br /> <br />
					Vă puteți exprima consimțământul pentru prelucrarea datelor
					în acest scop prin bifarea căsuței corespunzătoare de la
					momentul creării contului, sau ulterior creării contului, la
					secțiunea Informațiile contului meu.
					<br /> <br />
					Pentru dezabonarea de la primirea unor astfel de comunicări
					comerciale puteți folosi opţiunea de la finalul fiecărui
					e-mail/ sms conţinând comunicări comerciale. În plus, puteți
					să vă dezabonați prin accesarea secțiunii Informațiile
					contului meu.
					<br /> <br />
					Furnizarea datelor dumneavoastră în acest scop este
					voluntară. Refuzul furnizării consimțământului pentru
					prelucrarea datelor dumneavoastră în acest scop nu va avea
					urmări negative pentru dumneavoastră.
					<br /> <br />- în scopul efectuării diverselor analize,
					raportări privind modul de funcționare a Site-ului,
					realizarea de profiluri de preferinţe de consum, în
					principal, în vederea îmbunătăţiri experienței oferite pe
					Site.
					<br /> <br />
					Temei: Prelucrarea datelor dumneavoastră pentru acest scop
					are la bază interesul legitim al S.C. WYSAPP SMARTWEAR
					S.R.L. de a îmbunătății permanent experiența clienților pe
					Site. Furnizarea datelor dumneavoastră în acest scop este
					voluntară. Refuzul furnizării datelor pentru acest scop nu
					va avea urmări negative pentru dumneavoastră.
					<br /> <br />
					<strong>III.2.</strong> Dacă sunteți vizitator al Site-ului,
					S.C. WYSAPP SMARTWEAR S.R.L. prelucrează datele
					dumneavoastră cu caracter personal astfel:
					<br /> <br />- pentru activităţi de marketing, respectiv
					pentru transmiterea, prin intermediul mijloacelor de
					comunicare la distanţă (e-mail, sms), de comunicări
					comerciale privind produsele şi serviciile oferite de S.C.
					WYSAPP SMARTWEAR S.R.L., prin intermediul Site-ului.
					<br /> <br />
					Temei: Prelucrarea datelor dumneavoastră pentru acest scop
					are la bază consimțământul dumneavoastră, dacă alegeți să-l
					furnizați.
					<br /> <br />
					Vă puteți exprima consimțământul pentru prelucrarea datelor
					în acest scop prin completarea și bifarea căsuței
					corespunzătoare din formularul pentru abonarea la newsletter
					disponibil pe Site. Pentru dezabonarea de la primirea unor
					astfel de comunicări comerciale puteți folosi opţiunea de la
					finalul fiecărui e-mail/ sms conţinând comunicări
					comerciale.
					<br /> <br />
					Furnizarea datelor dumneavoastră în acest scop este
					voluntară. Refuzul furnizării consimțământului pentru
					prelucrarea datelor dumneavoastră în acest scop nu va avea
					urmări negative pentru dumneavoastră.
					<br /> <br />- pentru rezolvarea plângerilor, reclamaţiilor
					şi pentru a monitoriza traficul și a îmbunătăţii experiența
					dumneavoastră oferită pe Site.
					<br /> <br />
					Temei: Prelucrarea datelor dumneavoastră pentru acest scop
					are la bază interesul legitim al S.C. WYSAPP SMARTWEAR
					S.R.L. de a asigura funcționarea corectă a Site-ului, precum
					și pentru a îmbunătății permanent experiența vizitatorilor
					Site-ului, inclusiv prin soluționarea diferitelor
					comentarii, întrebări sau reclamații.
					<br /> <br />
					Furnizarea datelor dumneavoastră în acest scop este
					voluntară. Refuzul furnizării datelor pentru acest scop nu
					va avea urmări negative pentru dumneavoastră.
				</p>

				<p>
					<strong>IV. Durata pentru care vă prelucrăm datele</strong>
				</p>

				<p>
					Ca principiu, S.C. WYSAPP SMARTWEAR S.R.L. va prelucra
					datele dumneavoastră cu caracter personal atât cât este
					necesar pentru realizarea scopurilor de prelucrare
					menționate mai sus.
					<br />
					<br />
					În cazul în care sunteți client, vom prelucra datele
					dumneavoastră pe întreaga durată a raporturilor contractuale
					și ulterior conform obligaţiilor legale care revin în
					sarcina S.C. WYSAPP SMARTWEAR S.R.L. (de ex., în cazul
					documentelor justificative financiar-contabile pentru care
					termenul de păstrare prevăzut de lege este de 5 ani de la
					data încheierii exerciţiului financiar în cursul căruia au
					fost întocmite).
					<br />
					<br />
					În situaţia în care sunteți client și vă exercitați opțiunea
					de ştergere a contului de utilizator, prin apăsarea butonul
					Ștergere cont din secțiunea Informațiile contului meu,
					WysApp Srl va interpreta această acțiune ca opțiunea
					dumneavoastră de a vă dezabona de la primirea de comunicări
					comerciale prin care vă ținem la curent despre produsele și
					serviciile oferite prin intermediul site-ului.
					<br />
					<br />
					În acest sens, dacă alegeți să vă ștergeți contul de
					utilizator, nu vă vom mai trimite e-mailuri și/sau sms-uri
					de acest gen. Vă informăm că ștergerea contului va avea ca
					efect automat ștergerea datelor dumneavoastră cu caracter
					personal.
					<br />
					<br />
					În cazul în care doriți să nu vă mai fie prelucrate datele
					cu caracter personal sau dacă doriți ștergerea datelor, vă
					puteți exercita drepturile detaliate la punctul VII de mai
					jos.
					<br />
					<br />
					În cazul în care solicitați ştergerea contului, însă pe acel
					cont există cel puţin o comandă activă, cererea de ştergere
					a contului va putea fi înregistrată numai după livrarea
					produselor şi finalizarea ultimei comenzi active.
					<br />
					<br />
					Dacă vă retrageți consimțământul pentru prelucrarea datelor
					în scop de marketing, S.C. WYSAPP SMARTWEAR S.R.L. va înceta
					prelucrarea datelor dumneavoastră cu caracter personal în
					acest scop, fără însă a afecta prelucrările desfășurate de
					S.C. WYSAPP SMARTWEAR S.R.L. pe baza consimțământului
					exprimat de către dumneavoastră înainte de retragerea
					acestuia.
				</p>

				<p>
					<strong>V. Dezvăluirea datelor cu caracter personal</strong>
				</p>

				<p>
					Pentru îndeplinirea scopurilor de prelucrare, S.C. WYSAPP
					SMARTWEAR S.R.L. poate dezvălui datele dumneavoastră către
					parteneri, către terțe persoane sau entități care sprijină
					S.C. WYSAPP SMARTWEAR S.R.L. în desfășurarea activității
					prin intermediul Site-ului (de exemplu firme de curierat,
					furnizori de servicii IT), ori către autoritățile publice
					centrale/locale, în următoarele cazuri exemplificativ
					enumerate:
					<br />
					<br />- pentru administrarea Site-ului;
					<br />
					<br />- în situațiile în care această comunicare ar fi
					necesară pentru atribuirea de premii sau alte facilități
					persoanelor vizate, obținute ca urmare a participării lor la
					diverse campanii promoționale organizate de către S.C.
					WYSAPP SMARTWEAR S.R.L. prin intermediul Site-ului;
					<br />
					<br />- pentru menținerea, personalizarea și îmbunătățirea
					Site-ului și a serviciilor derulate prin intermediul lui;
					<br />
					<br />- pentru efectuarea analizei datelor, testarea și
					cercetarea, monitorizarea tendințelor de utilizare și
					activitate, dezvoltarea caracteristicilor de siguranță și
					autentificarea utilizatorilor;
					<br />
					<br />- pentru transmiterea de comunicări comerciale de
					marketing, în condițiile și limitele prevăzute de lege;
					<br />
					<br />- atunci când dezvăluirea datelor cu caracter personal
					este prevăzută de lege etc.
				</p>

				<p>
					<strong>VI. Transferul datelor cu caracter personal</strong>
				</p>

				<p>
					Datele cu caracter personal furnizate către S.C. WYSAPP
					SMARTWEAR S.R.L. pot fi transferate în afara României, dar
					doar către state din Uniunea Europeană.
					<br />
					<br />
					Aceasta sectiune se refera la datele necesare pentru
					executarea de catre S.C. WYSAPP SMARTWEAR S.R.L. a livrarii
					produselor comandate prin intermediul site-ului si nu are
					legatura cu datele publicate de catre dvs. in cadrul
					aplicatiei mobile.
					<br />
					<br />
					Datele publicate de catre dumneavoastra in aplicatia mobila
					nu vor fi prelucrate , transferate sau livrate nici unei
					entitati , in nici un scop (incluzand campanii de marketing
					, promovare , cercetari si analize ale continutului
					imaginilor si textului postat in aplicatie).
				</p>

				<p>
					<strong>VII. Drepturile de care beneficiați</strong>
				</p>

				<p>
					În condițiile prevăzute de legislația în materia prelucrării
					datelor cu caracter personal, în calitate de persoane
					vizate, beneficiați de următoarele drepturi:
					<br />
					<br />- dreptul la informare, respectiv dreptul de a primi
					detalii privind activitățile de prelucrare efectuate de
					către S.C. WYSAPP SMARTWEAR S.R.L., conform celor descrise
					în prezentul document;
					<br />
					<br />- dreptul de acces la date, respectiv dreptul de a
					obține confirmarea din partea S.C. WYSAPP SMARTWEAR S.R.L.
					cu privire la prelucrarea datelor cu caracter personal,
					precum și detalii privind activitățile de prelucrare precum
					modalitatea în care sunt prelucrate datele, scopul în care
					se face prelucrarea, destinatarii sau categoriile de
					destinatari ai datelor, etc;
					<br />
					<br />- dreptul la rectificare, respectiv dreptul de a
					obține corectarea, fără întârzieri justificate, de către
					S.C. WYSAPP SMARTWEAR S.R.L. a datelor cu caracter personal
					inexacte/ nejustificate, precum și completarea datelor
					incomplete; Rectificarea/ completarea va fi comunicată
					fiecărui destinatar la care au fost transmise datele, cu
					excepția cazului în care acest lucru se dovedește imposibil
					sau presupune eforturi disproporționate.
					<br />
					<br />- dreptul la ștergerea datelor, fără întârzieri
					nejustificate, („dreptul de a fi uitat”), în cazul in care
					se aplică unul dintre următoarele motive:
					<br /> - acestea nu mai sunt necesare pentru îndeplinirea
					scopurilor pentru care au fost colectate sau prelucrate;\n +
					în cazul în care este retras consimțământul și nu există
					niciun alt temei juridic pentru prelucrare;\n + în cazul în
					care persoana vizată se opune prelucrării și nu există
					motive legitime care să prevaleze;\n + în cazul în care
					datele cu caracter personal au fost prelucrate ilegal;\n +
					în cazul în care datele cu caracter personal trebuie șterse
					pentru respectarea unei obligații legale;\n + datele cu
					caracter personal au fost colectate în legătură cu oferirea
					de servicii ale societății informaționale conform dreptului
					Uniunii sau al dreptului intern sub incidenta căruia se află
					operatorul.
					<br />
					<br />- Este posibil ca, în urma solicitării de ștergere a
					datelor, S.C. WYSAPP SMARTWEAR S.R.L. să anonimizeze aceste
					date (lipsindu-le astfel de caracterul personal) și să
					continue în aceste condiții prelucrarea pentru scopuri
					statistice;
					<br />
					<br />- dreptul la restricționarea prelucrării în măsura în
					care:
					<br /> - persoana contestă exactitatea datelor, pe o
					perioadă care ne permite verificarea corectitudinii datelor;
					<br /> - prelucrarea este ilegală, iar persoana vizată se
					opune ștergerii datelor cu caracter personal, solicitând în
					schimb restricționarea utilizării lor;
					<br /> - operatorul nu mai are nevoie de datele cu caracter
					personal în scopul prelucrării, dar persoana vizată i le
					solicită pentru constatarea, exercitarea sau apărarea unui
					drept în instanță; sau
					<br /> - persoana vizată s-a opus prelucrării (altele decât
					cele de marketing direct), pentru intervalul de timp în care
					se verifică dacă drepturile legitime ale operatorului
					prevalează asupra celor ale persoanei vizate.
					<br />
					<br />- dreptul la portabilitatea datelor, respectiv (i)
					dreptul de a primi datele cu caracter personal într-o
					modalitate structurată, folosită în mod obișnuit și într-un
					format ușor de citit, precum și (ii) dreptul ca aceste date
					să fie transmise de către S.C. WYSAPP SMARTWEAR S.R.L. către
					alt operator de date ( curier , autoritatea fiscala ) , în
					măsura în care sunt îndeplinite condițiile prevăzute de
					lege;
					<br />
					<br />- dreptul la opoziție – în ceea ce privește
					activitățile de prelucrare se poate exercita prin
					transmiterea unei solicitări conform celor indicate mai jos;
					<br />
					<br />- în orice moment, din motive legate de situația
					particulară în care se află persoana vizată, ca datele care
					o vizează să fie prelucrate în temeiul interesului legitim
					al S.C. WYSAPP SMARTWEAR S.R.L. sau în temeiul interesului
					public, cu excepția cazurilor în care S.C. WYSAPP SMARTWEAR
					S.R.L. poate demonstra că are motive legitime și imperioase
					care justifică prelucarea și care prevalează asupra
					intereselor, drepturilor și libertăților persoanelor vizate
					sau că scopul este constatarea, exercitarea sau apararea
					unui drept în instanță;
					<br /> - în orice moment, în mod gratuit și fără nicio
					justificare, că datele care o vizează să fie prelucrate în
					scop de marketing direct.
					<br />
					<br />- dreptul de a nu fi supus unei decizii individuale
					automate, respectiv dreptul de a nu fi subiectul unei
					decizii luate numai pe baza unor activități de prelucrare
					automate, inclusiv crearea de profiluri, care produce efecte
					juridice care privesc persoana vizată sau o afectează în mod
					similar într-o măsură semnificativă;
					<br />
					<br />- dreptul de a vă adresa Autorităţii Naţionale de
					Supraveghere a Prelucrării Datelor cu Caracter Personal sau
					instanțelor competente, în măsura în care considerați
					necesar.
					<br />
					<br />
					Pentru orice întrebări suplimentare cu privire la modul în
					care datele cu caracter personal sunt prelucrate și pentru a
					vă exercita drepturile menționate mai sus, vă rugăm să vă
					adresați la adresa de email: contact@wecodeqr.com.
				</p>
			</div>
		</>
	) : (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>GDPR </h1>

				<p>
					<strong>I. General Information</strong>
				</p>
				<p>
					The website wecodeqr.com is operated by the company S.C.
					WYSAPP SMARTWEAR S.R.L., headquartered in Ilfov County,
					Voluntari City, Sos. Bucuresti Nord, no. 10B, Building B1,
					Sc. C, 3rd Floor, Apt. 388, registered at Registrul
					Compertului under number J23/1321/2023.
					<br />
					<br />
					WYSAPP is a secure computer application accessible through a
					mobile phone with an Internet connection, where users can
					access, modify, and publish images, text, and links to
					various websites by scanning a QR code displayed on various
					clothing items sold by S.C. WYSAPP SMARTWEAR S.R.L. or by
					its resellers, partners, and customers.
				</p>

				<p>
					<strong>II. Categories of personal data processed.</strong>
				</p>
				<p>
					<ul>
						<li>
							<strong>II.1.</strong> If you are a user of the
							website and the WysApp mobile application, WysApp
							Srl will process your personal data such as name,
							phone number, email address, delivery address, as
							well as the data published by you through the mobile
							application (photos, digital images, links), data
							regarding your use of the website, such as your
							behavior/preferences/habits within wecodeqr.com, and
							any other categories of data you provide directly in
							the context of creating a user account, placing an
							order through the website, or any other way
							resulting from the use of the website.
							<br /> <br />
							If you use your Facebook or Google account to create
							a user account on the website or mobile application,
							S.C. WYSAPP SMARTWEAR S.R.L. will process the
							following publicly displayed profile data provided
							by those applications: username, email address.
							<br /> <br />
							If you choose to create a user account only before
							finalizing the order of a product available on the
							website, your email address will be requested, based
							on which an account will be automatically created.
							If you do not complete the order, the provided email
							address and other data will not be stored by WysApp
							Srl, and the created account will be automatically
							deleted.
						</li>
						<li>
							<strong>II.2.</strong> If you are a visitor of the
							website, S.C. WYSAPP SMARTWEAR S.R.L. will process
							your personal data that you provide directly in the
							context of using the website, such as the data you
							provide in the contact/questions/complaints section,
							to the extent that you contact us in this way.
						</li>
					</ul>
				</p>

				<p>
					<strong>
						III. The purposes and legal grounds of data processing
					</strong>
				</p>

				<p>
					<strong>III.1.</strong> If you are a customer of the Site,
					WysApp Srl processes your personal data for the following
					purposes:
					<br />
					<br />
					To carry out the contractual relationship between you and
					S.C. WYSAPP SMARTWEAR S.R.L., including the processing,
					validation, dispatch, and invoicing of orders placed on the
					Site, informing you about the status of your orders,
					organizing product returns, etc.
					<br />
					<br />
					Legal grounds: The processing of your data for this purpose
					is based on the contract concluded between you and S.C.
					WYSAPP SMARTWEAR S.R.L., as defined in the Terms and
					Conditions of WysApp. Providing your personal data is
					necessary for the execution of this contract. Refusing to
					provide the data may result in the impossibility of carrying
					out the contractual relationship between you and S.C. WYSAPP
					SMARTWEAR S.R.L.
					<br />
					<br />
					To fulfill legal obligations incumbent upon S.C. WYSAPP
					SMARTWEAR S.R.L. in the context of services provided through
					the Site, including fiscal obligations and archiving
					obligations.
					<br />
					<br />
					Legal grounds: The processing of your data for this purpose
					is necessary based on legal obligations. Providing your data
					for this purpose is necessary. Refusing to provide the data
					may result in S.C. WYSAPP SMARTWEAR S.R.L. being unable to
					fulfill its legal obligations and therefore unable to
					provide you with services through the Site.
					<br />
					<br />
					For marketing activities, including the transmission of
					commercial communications regarding products and services
					offered by S.C. WYSAPP SMARTWEAR S.R.L. through distance
					communication means (email, SMS) via the Site.
					<br />
					<br />
					Legal grounds: The processing of your data for this purpose
					is based on your consent, if you choose to provide it.
					<br />
					<br />
					You can give your consent for the processing of data for
					this purpose by checking the corresponding box at the time
					of creating your account or later, in the My Account
					section.
					<br />
					<br />
					To unsubscribe from receiving such commercial
					communications, you can use the option provided at the end
					of each email/SMS containing commercial communications.
					Additionally, you can unsubscribe by accessing the My
					Account section.
					<br />
					<br />
					Providing your data for this purpose is voluntary. Refusing
					to give consent for the processing of your data for this
					purpose will not have negative consequences for you.
					<br />
					<br />
					For conducting various analyses and reports on the
					functioning of the Site, creating consumer preference
					profiles, primarily to improve the user experience on the
					Site.
					<br />
					<br />
					Legal grounds: The processing of your data for this purpose
					is based on the legitimate interest of S.C. WYSAPP SMARTWEAR
					S.R.L. in continuously improving the customer experience on
					the Site. Providing your data for this purpose is voluntary.
					Refusing to provide data for this purpose will not have
					negative consequences for you.
					<br />
					<br />
					<strong>III.2.</strong> If you are a visitor of the Site,
					S.C. WYSAPP SMARTWEAR S.R.L. processes your personal data as
					follows:
					<br />
					<br />
					For marketing activities, including the transmission of
					commercial communications regarding products and services
					offered by S.C. WYSAPP SMARTWEAR S.R.L. through distance
					communication means (email, SMS) via the Site.
					<br />
					<br />
					Legal grounds: The processing of your data for this purpose
					is based on your consent, if you choose to provide it.
					<br />
					<br />
					You can give your consent for the processing of data for
					this purpose by completing and checking the corresponding
					box in the newsletter subscription form available on the
					Site. To unsubscribe from receiving such commercial
					communications, you can use the option provided at the end
					of each email/SMS containing commercial communications.
					<br />
					<br />
					Providing your data for this purpose is voluntary. Refusing
					to give consent for the processing of your data for this
					purpose will not have negative consequences for you.
					<br />
					<br />
					For the resolution of complaints and to monitor traffic and
					improve the user experience offered on the Site.
					<br />
					<br />
					Legal grounds: The processing of your data for this purpose
					is based on the legitimate interest of S.C. WYSAPP SMARTWEAR
					S.R.L. in ensuring the proper functioning of the Site and
					continuously improving the visitor experience on the Site,
					including addressing various comments, questions, or
					complaints.
					<br />
					<br />
					Providing your data for this purpose is voluntary. Refusing
					to provide data for this purpose will not have negative
					consequences for you.
				</p>

				<p>
					<strong>
						IV. The duration for which we process your data.
					</strong>
				</p>

				<p>
					As a principle, S.C. WYSAPP SMARTWEAR S.R.L. will process
					your personal data for as long as necessary to fulfill the
					processing purposes mentioned above.
					<br />
					<br />
					If you are a customer, we will process your data throughout
					the duration of the contractual relationship and
					subsequently in accordance with the legal obligations of
					S.C. WYSAPP SMARTWEAR S.R.L. (e.g., in the case of
					financial-accounting supporting documents, which are
					required to be kept for 5 years from the end of the
					financial year in which they were prepared).
					<br />
					<br />
					In the event that you are a customer and choose to delete
					your user account by clicking the &quot;Delete Account&quot;
					button in the &quot;My Account Information&quot; section,
					WysApp Srl will interpret this action as your choice to
					unsubscribe from receiving commercial communications keeping
					you informed about the products and services offered through
					the website.
					<br />
					<br />
					Therefore, if you choose to delete your user account, we
					will no longer send you emails and/or SMS of this nature.
					Please note that deleting your account will automatically
					result in the deletion of your personal data.
					<br />
					<br />
					If you do not want your personal data to be processed or if
					you want your data to be deleted, you can exercise your
					rights as detailed in Section VII below.
					<br />
					<br />
					If you request the deletion of your account but there is at
					least one active order on that account, the account deletion
					request will only be processed after the delivery of the
					products and completion of the last active order.
					<br />
					<br />
					If you withdraw your consent for data processing for
					marketing purposes, S.C. WYSAPP SMARTWEAR S.R.L. will cease
					the processing of your personal data for this purpose,
					without affecting the processing carried out by S.C. WYSAPP
					SMARTWEAR S.R.L. based on the consent you provided prior to
					its withdrawal.
				</p>

				<p>
					<strong>V. Disclosure of personal data.</strong>
				</p>

				<p>
					For the purpose of processing, S.C. WYSAPP SMARTWEAR S.R.L.
					may disclose your personal data to partners, third parties,
					or entities that support S.C. WYSAPP SMARTWEAR S.R.L. in
					conducting its activities through the Website (such as
					courier companies, IT service providers), or to
					central/local public authorities, in the following
					illustrative cases:
					<br />
					<br />- For website administration;
					<br />
					<br />- In situations where such communication is necessary
					for awarding prizes or other benefits to individuals,
					obtained as a result of their participation in various
					promotional campaigns organized by S.C. WYSAPP SMARTWEAR
					S.R.L. through the Website;
					<br />
					<br />- For the maintenance, personalization, and
					improvement of the Website and the services provided through
					it;
					<br />
					<br />- For data analysis, testing, research, monitoring
					usage trends and activities, developing security features,
					and user authentication;
					<br />
					<br />- For sending marketing communications, in accordance
					with the applicable law;
					<br />
					<br />- When the disclosure of personal data is required by
					law, etc.
				</p>

				<p>
					<strong>VI. Transfer of personal data</strong>
				</p>

				<p>
					Personal data provided to S.C. WYSAPP SMARTWEAR S.R.L. may
					be transferred outside of Romania, but only to states within
					the European Union.
					<br />
					<br />
					This section refers to the data necessary for the execution
					of product deliveries ordered through the website and is
					unrelated to the data published by you within the mobile
					application.
					<br />
					<br />
					The data published by you in the mobile application will not
					be processed, transferred, or delivered to any entity for
					any purpose (including marketing campaigns, promotion,
					research, and analysis of the content of images and text
					posted in the application).
				</p>

				<p>
					<strong>VII. Rights you have</strong>
				</p>

				<p>
					In accordance with the legislation on the processing of
					personal data, as data subjects, you have the following
					rights:
					<br />
					<br />- The right to be informed, which includes the right
					to receive details about the processing activities carried
					out by S.C. WYSAPP SMARTWEAR S.R.L., as described in this
					document.
					<br />
					<br />- The right of access to data, which includes the
					right to obtain confirmation from S.C. WYSAPP SMARTWEAR
					S.R.L. regarding the processing of personal data, as well as
					details about the processing activities such as the manner
					in which the data is processed, the purpose of the
					processing, the recipients or categories of recipients of
					the data, etc.
					<br />
					<br />- The right to rectification, which includes the right
					to obtain without undue delay the rectification by S.C.
					WYSAPP SMARTWEAR S.R.L. of inaccurate/unjustified personal
					data, as well as the completion of incomplete data. The
					rectification/completion shall be communicated to each
					recipient to whom the data have been disclosed, unless this
					proves impossible or involves disproportionate effort.
					<br />
					<br />- The right to erasure of data, without undue delay
					(&quot;right to be forgotten&quot;), in case one of the
					following reasons applies: <br />
					-They are no longer necessary for the purposes for which
					they were collected or processed. <br />
					-The consent has been withdrawn, and there is no other legal
					ground for the processing.
					<br />- The data subject objects to the processing, and
					there are no overriding legitimate grounds for the
					processing. <br />
					-The personal data have been unlawfully processed.
					<br />- The personal data must be erased for compliance with
					a legal obligation.
					<br />- The personal data have been collected in relation to
					the offer of information society services in accordance with
					Union or Member State law under which the controller
					operates.
					<br />
					<br />- It is possible that, following a request for data
					erasure, S.C. WYSAPP SMARTWEAR S.R.L. will anonymize this
					data (thus removing its personal character) and continue
					processing it for statistical purposes.
					<br />
					<br />- The right to restriction of processing to the extent
					that: <br />- The accuracy of the data is contested by the
					data subject, for a period enabling the controller to verify
					the accuracy of the personal data.
					<br />- The processing is unlawful, and the data subject
					opposes the erasure of the personal data and requests the
					restriction of their use instead.
					<br />- The controller no longer needs the personal data for
					the purposes of the processing, but the data subject
					requires them for the establishment, exercise, or defense of
					legal claims. <br />
					-The data subject has objected to processing (other than
					direct marketing) pending the verification whether the
					legitimate grounds of the controller override those of the
					data subject.
					<br />
					<br />- The right to data portability, which includes the
					right to receive personal data in a structured, commonly
					used, and machine-readable format, as well as the right to
					transmit those data to another data controller (such as a
					courier or tax authority), where technically feasible and
					provided by law.
					<br />
					<br />- The right to object, which can be exercised by
					submitting a request in accordance with the instructions
					provided.
					<br />
					<br />- The right to withdraw consent at any time if the
					processing is based on the legitimate interest of S.C.
					WYSAPP SMARTWEAR S.R.L. or the public interest, except where
					S.C. WYSAPP SMARTWEAR S.R.L. can demonstrate compelling
					legitimate grounds for the processing that override the
					interests, rights, and freedoms of the data subject, or for
					the establishment, exercise, or defense of legal claims. The
					right to object also applies to direct marketing purposes,
					in which case the data subject&quot;s personal data shall
					not be processed for such purposes.
					<br />
					<br />- The right not to be subject to a decision based
					solely on automated processing, including profiling, which
					produces legal effects concerning the data subject or
					similarly significantly affects them.
					<br />
					<br />- The right to lodge a complaint with the National
					Supervisory Authority for Personal Data Processing or the
					competent courts, if deemed necessary.
					<br />
					<br />
					For any further questions regarding the processing of
					personal data and to exercise the aforementioned rights,
					please contact us at the following email address:
					contact@wecodeqr.com.
				</p>
			</div>
		</>
	);
};

export default GDPR;
