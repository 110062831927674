// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_about_image__9Z24p {
  height: inherit;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/sections/about.module.sass"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,eAAA;AADJ","sourcesContent":["\r\n\r\n.about_image\r\n    height: inherit\r\n    max-width: 100%\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about_image": `about_about_image__9Z24p`
};
export default ___CSS_LOADER_EXPORT___;
