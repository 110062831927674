"use client";

import { Box, Button, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";

import Navbar from "./navbar";
import { Translation } from "../../../utils/translate";

export default function Hero() {
	const SectionBox = styled(Box)(({ theme }) => ({
		background:
			"url(graphic_elements.svg) center no-repeat , linear-gradient(74.43deg, #147cf3 0%, #5ba4f2 100%)",
		display: "flex",
		flexDirection: "column",
	}));

	const CustomBox = styled(Box)(({ theme }) => ({
		display: "flex",
		justifyContent: "center",
		gap: theme.spacing(5),
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(5),
		marginRight: "0",
		[theme.breakpoints.down("md")]: {
			alignItems: "center",
			textAlign: "center",
		},
		[theme.breakpoints.down("sm")]: {
			minHeight: "40rem",
			marginLeft: "0",
			marginTop: "0",
		},
	}));

	const ContentBox = styled(Box)(({ theme }) => ({
		flex: "0.8",
		justifyContent: "center",
		paddingTop: "1em",
		paddingBottom: "1em",
		paddingLeft: "3rem",
		paddingRight: "3rem",
		[theme.breakpoints.down("md")]: {
			paddingLeft: "1rem",
			paddingRight: "1rem",
		},
	}));

	const Title = styled(Typography)(({ theme }) => ({
		fontSize: "48px",
		color: "#000336",
		fontWeight: "bold",
		margin: theme.spacing(4, 0, 4, 0),
		[theme.breakpoints.down("md")]: {
			marginTop: "-2rem",
			fontSize: "40px",
		},
		[theme.breakpoints.down(700)]: {
			fontSize: "32px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "32px",
			fontWeight: "700",
			marginBottom: "3rem",
		},
	}));

	const Description = styled(Typography)(({ theme }) => ({
		fontSize: "16px",
		color: "#fff",
		weight: "400",
		marginTop: theme.spacing(2),
		marginBottom: "2vw",

		[theme.breakpoints.down("md")]: {
			fontSize: "16px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			fontWeight: "400",
			marginBottom: "3rem",
		},
	}));

	const CustomButton = styled(Button)(({ theme }) => ({
		background: "#fff",
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		borderRadius: "10px",
		width: "24rem",
		maxWidthheight: "4rem",

		marginBottom: "2rem",

		color: "#000",
		fontWeight: "400",
		fontSize: "20px",
		textTransform: "none",

		[theme.breakpoints.down("md")]: {
			width: "30vw",
		},

		[theme.breakpoints.down("sm")]: {
			width: "52vw",
		},
	}));

	const PhoneImg = styled(Box)(({ theme }) => ({
		flex: "1",
		display: "flex",
		justifyContent: "center",

		[theme.breakpoints.down("md")]: {
			width: "30vw",
		},

		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	}));

	const StoreBox = styled(Box)(({ theme }) => ({
		display: "inline-flex",
		maxWidth: "25rem",
		gap: theme.spacing(1),

		[theme.breakpoints.down("md")]: {
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "60vw",
			display: "flex",
			flexDirection: "column",
			gap: "0",
		},
	}));

	const StoreImage = styled("img")(({ theme }) => ({
		width: "100%",
	}));

	return (
		<SectionBox id="home-section">
			<Container>
				<Navbar />
				<CustomBox>
					<ContentBox>
						<Title
							variant="h1"
							sx={{ color: "#fff", marginBottom: "1vw" }}
						>
							We Code QR
						</Title>
						<Description>
							{Translation.Translate("hero", "description")}
						</Description>

						{/* <CustomButton
							className="custom-btn"
							href="https://www.shop.wearyourspace.com/"
						>
							{Translation.Translate("hero", "shopnow")}
						</CustomButton> */}

						<StoreBox>
							<a
								href="https://apps.apple.com/ro/app/wear-your-space/id6449791428"
								target="_blank"
								rel="noreferrer"
							>
								<StoreImage
									className="mt-2"
									src="app_store_button.png"
									alt="heroImg"
								/>
							</a>

							<a
								href="https://play.google.com/store/apps/details?id=com.wcq.app"
								target="_blank"
								rel="noreferrer"
							>
								<StoreImage
									className="mt-2"
									src="google_store_button.png"
									alt="heroImg"
								/>
							</a>
						</StoreBox>
					</ContentBox>

					<PhoneImg>
						<img
							src="hero_image.png"
							alt="heroImg"
							style={{
								maxWidth: "100%",
								marginBottom: "2rem",
							}}
						/>
					</PhoneImg>
				</CustomBox>
			</Container>
		</SectionBox>
	);
}
