// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-info-page {
  width: 100%;
  margin: 40px auto;
}
.qr-info-page .no-information {
  text-align: center;
  color: #000;
  font-size: 32px;
}
.qr-info-page .loader {
  margin: 0 auto;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.qr-info-page .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.qr-info-page .content img {
  max-width: 100%;
}
@media (max-width: 992px) {
  .qr-info-page .content img {
    max-width: 80%;
  }
}
.qr-info-page .content .text {
  text-align: center;
  font-size: 24px;
  margin-top: 10px;
}

.qr-info .navbar-custom-container {
  background: linear-gradient(74.43deg, #147cf3 0%, #5ba4f2 100%) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/QRinfo/QRinfo.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,iBAAA;AACD;AACC;EACC,kBAAA;EACA,WAAA;EACA,eAAA;AACF;AAEC;EACC,cAAA;EACA,wBAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;AAAF;AAGC;EACC,aAAA;EACA,sBAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AADF;AAGE;EACC,eAAA;AADH;AAGG;EAHD;IAIE,cAAA;EAAF;AACF;AAGE;EACC,kBAAA;EACA,eAAA;EACA,gBAAA;AADH;;AAOC;EACC,0EAAA;AAJF","sourcesContent":[".qr-info-page {\r\n\twidth: 100%;\r\n\tmargin: 40px auto;\r\n\r\n\t.no-information {\r\n\t\ttext-align: center;\r\n\t\tcolor: #000;\r\n\t\tfont-size: 32px;\r\n\t}\r\n\r\n\t.loader {\r\n\t\tmargin: 0 auto;\r\n\t\tdisplay: flex !important;\r\n\t\talign-items: center;\r\n\t\tflex-direction: column;\r\n\t\tjustify-content: center;\r\n\t}\r\n\r\n\t.content {\r\n\t\tdisplay: flex;\r\n\t\tflex-direction: column;\r\n\t\twidth: 100%;\r\n\t\talign-items: center;\r\n\t\tjustify-content: center;\r\n\t\tmax-width: 100%;\r\n\r\n\t\timg {\r\n\t\t\tmax-width: 100%;\r\n\r\n\t\t\t@media (max-width: 992px) {\r\n\t\t\t\tmax-width: 80%;\r\n\t\t\t}\r\n\t\t}\r\n\r\n\t\t.text {\r\n\t\t\ttext-align: center;\r\n\t\t\tfont-size: 24px;\r\n\t\t\tmargin-top: 10px;\r\n\t\t}\r\n\t}\r\n}\r\n\r\n.qr-info {\r\n\t.navbar-custom-container {\r\n\t\tbackground: linear-gradient(\r\n\t\t\t74.43deg,\r\n\t\t\t#147cf3 0%,\r\n\t\t\t#5ba4f2 100%\r\n\t\t) !important;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
