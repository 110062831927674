import { useEffect, useState } from "react";
import { requestProvider } from "../../../services/request.service";
import { BACKEND_ROUTES } from "../../../utils/routes";
import secureLocalStorage from "react-secure-storage";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { useNavigate, useParams } from "react-router";

const Order = () => {
	const [order, setOrder] = useState<any>();
	const value = useParams();
	const navigator = useNavigate();

	useEffect(() => {
		if (!value) {
			navigator("/application-backdoor/dashboard");
		}

		getOrder();
	}, []);

	const getOrder = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.GET_ORDER + "/" + value.id,
				"GET",
				{},
				{
					"Content-Type": "application/json",
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					if (response.status === 200) {
						setOrder({
							address: response.data.data.address,
							city: response.data.data.city,
							country: response.data.data.country,
							county: response.data.data.county,
							credits: response.data.data.credits,
							email: response.data.data.email,
							firstName: response.data.data.firstName,
							lastName: response.data.data.lastName,
							phoneNumber: response.data.data.phoneNumber,
							status: response.data.data.status,
						});
					}
				})
				.catch((error) => {});
		} catch (error) {}
	};

	return (
		<div className="admin-interface">
			{/* Search bar */}

			{/* // A list of all the codes without form */}
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="card mt-4">
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<h4 className="card-title">
											Order details
										</h4>
										<hr />
									</div>

									<div className="col-md-6">
										<h5 className="card-title">Name</h5>
										<p className="card-text">
											{order?.firstName} {order?.lastName}
										</p>

										<h5 className="card-title">Email</h5>
										<p className="card-text">
											{order?.email}
										</p>

										<h5 className="card-title">
											Phone number
										</h5>
										<p className="card-text">
											{order?.phoneNumber}
										</p>

										<h5 className="card-title">Address</h5>
										<p className="card-text">
											{order?.address}
										</p>

										<h5 className="card-title">City</h5>
										<p className="card-text">
											{order?.city}
										</p>

										<h5 className="card-title">County</h5>
										<p className="card-text">
											{order?.county}
										</p>

										<h5 className="card-title">Country</h5>
										<p className="card-text">
											{order?.country}
										</p>

										<h5 className="card-title">Status</h5>
										<p className="card-text">
											{order?.status}
										</p>

										<h5 className="card-title">Credits</h5>
										<p className="card-text">
											{order?.credits}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Order;
