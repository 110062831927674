import { Translation } from "../../utils/translate";
import Navbar from "../QRinfo/navbar";

const Returnare = () => {
	return Translation.GetLanguage() === "ro" ? (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>Politica de Returnare și Anulare pentru WecodeQR</h1>
				<h2>Politica de Returnare</h2>
				<p>
					Înțelegem că pot apărea circumstanțe în care trebuie să returnați achiziția efectuată prin aplicația
					WecodeQR. Pentru a iniția o returnare, vă rugăm să trimiteți o cerere de returnare la adresa noastră
					de email: <a href="mailto:clients@wecodeqr.com">clients@wecodeqr.com</a>. Vom răspunde cât mai
					repede posibil cu instrucțiuni suplimentare.
				</p>
				<p>Vă rugăm să rețineți că returnările sunt supuse următoarelor condiții:</p>
				<ul>
					<li>Cererea de returnare trebuie făcută în termen de 30 zile de la data achiziției.</li>
				</ul>

				<h2>Politica de Anulare</h2>
				<p>
					Dacă doriți să anulați comanda, vă rugăm să ne contactați imediat. Vom face tot posibilul să vă
					satisfacem solicitarea dacă comanda nu a fost deja procesată. Cu toate acestea, odată ce comanda a
					fost procesată și codurile QR au fost generate, anularea ar putea să nu mai fie posibilă.
				</p>
				<p>
					Pentru a solicita anularea, vă rugăm să ne trimiteți un email la{" "}
					<a href="mailto:clients@wecodeqr.com">clients@wecodeqr.com</a> cu detaliile comenzii. Vom răspunde
					prompt pentru a confirma starea anulării.
				</p>
			</div>
		</>
	) : (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>Return and Cancellation Policy for WecodeQR</h1>
				<p>
					We understand that circumstances may arise where you need to return your purchase made through the
					WecodeQR application. In order to initiate a return, please send a return request to our email
					address: <a href="mailto:clients@wecodeqr.com">clients@wecodeqr.com</a>. We will respond as quickly
					as possible with further instructions.
				</p>
				<p>Please note that returns are subject to the following conditions:</p>
				<ul>
					<li>The return request must be made within 30 days from the date of purchase.</li>
				</ul>

				<h2>Cancellation Policy</h2>
				<p>
					If you wish to cancel your order, please contact us immediately. We will do our best to accommodate
					your request if the order has not already been processed. However, once the order has been processed
					and the QR codes have been generated, cancellation may not be possible.
				</p>
				<p>
					To request a cancellation, please email us at{" "}
					<a href="mailto:clients@wecodeqr.com">clients@wecodeqr.com</a> with your order details. We will
					respond promptly to confirm the cancellation status.
				</p>
			</div>
		</>
	);
};

export default Returnare;
