import React, { useEffect, useState } from "react";
import "../AdminInterface.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { requestProvider } from "../../../services/request.service";
import { BACKEND_ROUTES } from "../../../utils/routes";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { ITEMS_PER_PAGE } from "../../../utils/constants";
import Pagination from "@mui/material/Pagination";
import { FaEye, FaFileExport } from "react-icons/fa";
import { BiExport } from "react-icons/bi";
import { useNavigate, useParams } from "react-router";

const UserDetails = () => {
	const value = useParams();
	const navigate = useNavigate();

	const [userId, setUserId] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [credits, setCredits] = useState(0);

	const [creditsModal, setCreditsModal] = useState(false);
	const [newCredits, setNewCredits] = useState(0);
	const [responseMessage, setResponseMessage] = useState("");
	const [responseModal, setResponseModal] = useState(false);

	const [codes, setCodes] = useState<any[]>([]);
	const [pageIndex, setPageIndex] = useState(0);

	const modalRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!value) {
			navigate("/application-backdoor/users");
		}

		getUserDetails();
		getCodes();

		document.addEventListener("mousedown", handleClickOutside);
	}, []);

	const getUserDetails = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.USER_DETAILS,
				"POST",
				{
					id: value.id,
				},
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				}
			).then((res) => {
				if (res.status === 200) {
					setUserId(res.data.data.userId);
					setUserEmail(res.data.data.email);
					setFirstName(res.data.data.firstName);
					setLastName(res.data.data.lastName);
					setCredits(res.data.data.credits);
				} else {
					navigate("/application-backdoor/users");
				}
			});
		} catch (err) {}
	};

	const getCodes = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.USER_CODES,
				"POST",
				{
					userId: value.id,
				},
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				}
			).then((res) => {
				if (res.status === 200) {
					setCodes(res.data);
				}
			});
		} catch (err) {}
	};

	const changePage = (event: any, page: number) => {
		setPageIndex(page - 1);
	};

	const handleClickOutside = (event: any) => {
		if (
			modalRef.current &&
			!modalRef.current.contains(event.target as Node)
		) {
			setCreditsModal(false);
			setResponseModal(false);
		}
	};

	const showModifyCreditsModal = () => {
		setCreditsModal(true);
	};

	const hidemodifyCreditsModal = () => {
		setCreditsModal(false);
	};

	const modifyCredits = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.MODIFY_USER_CREDITS,
				"POST",
				{
					userId: value.id,
					credits: newCredits,
				},
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				}
			).then((res) => {
				setCredits(newCredits);
				setCreditsModal(false);

				setResponseMessage(res.data.message);
				setResponseModal(true);
			});
		} catch (err) {}
	};

	return (
		<div className="admin-interface">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h1>
							User: {firstName} {lastName}
						</h1>
					</div>
				</div>
				{userId === "" ? (
					<div className="row">
						<div className="col-12">
							<p>No user found</p>
						</div>
					</div>
				) : (
					<>
						<div className="row">
							<div className="col-12">
								<div>
									<p>Email: {userEmail}</p>
									<p>First Name: {firstName}</p>
									<p>Last Name: {lastName}</p>
									<p>Credits: {credits}</p>
								</div>
							</div>
						</div>

						{/* Modify credits button */}
						<div className="row mb-2">
							<div className="col-12">
								<button
									className="btn btn-primary"
									onClick={() => {
										showModifyCreditsModal();
									}}
								>
									Modify credits
								</button>
							</div>
						</div>
					</>
				)}

				{/* Table with codes */}
				<h2>Codes</h2>

				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="card mt-4">
								<div className="card-body">
									<Table className="w-100">
										<Thead>
											<Tr>
												<Th>Nr.</Th>
												<Th>Value</Th>
												<Th>Status</Th>
												<Th>Actions</Th>
											</Tr>
										</Thead>

										<Tbody>
											{codes
												.slice(
													pageIndex * ITEMS_PER_PAGE,
													(pageIndex + 1) *
														ITEMS_PER_PAGE
												)
												.map((transaction, index) => (
													<Tr key={index}>
														<Td>
															{pageIndex *
																ITEMS_PER_PAGE +
																index +
																1}
														</Td>
														<Td>
															{transaction.value}
														</Td>
														<Td>
															{transaction.status ??
																"-"}
														</Td>
														<Td>
															<button
																className="btn btn-primary"
																onClick={() => {
																	navigate(
																		"/application-backdoor/code/" +
																			transaction.value
																	);
																}}
															>
																<FaEye className="m-0 p-0" />
															</button>
														</Td>
													</Tr>
												))}
										</Tbody>
									</Table>

									{codes.length > ITEMS_PER_PAGE && (
										<Pagination
											className="cards-pagination mt-5 align-items-center justify-content-center"
											count={
												Math.floor(
													codes.length /
														ITEMS_PER_PAGE
												) + 1
											}
											variant="outlined"
											shape="rounded"
											onChange={changePage}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Modify credits modal */}
			<div
				className="modal"
				id="modifyCreditsModal"
				tabIndex={-1}
				aria-labelledby="modifyCreditsModalLabel"
				aria-hidden={creditsModal ? "false" : "true"}
				style={creditsModal ? { display: "block" } : {}}
			>
				<div
					className="modal-dialog modal-dialog-centered"
					ref={modalRef}
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5
								className="modal-title"
								id="modifyCreditsModalLabel"
							>
								Modify credits
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={hidemodifyCreditsModal}
							/>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12">
									<div className="mb-3">
										<label
											htmlFor="credits"
											className="form-label"
										>
											Credits
										</label>
										<input
											type="number"
											className="form-control"
											id="credits"
											onChange={(e) =>
												setNewCredits(
													parseInt(e.target.value, 10)
												)
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-primary"
								onClick={modifyCredits}
							>
								Modify
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* Result modal */}
			<div
				className="modal"
				id="resultModal"
				tabIndex={-1}
				aria-labelledby="resultModalLabel"
				aria-hidden={responseModal ? "false" : "true"}
				style={responseModal ? { display: "block" } : {}}
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-body">
							<div className="row">
								<div className="col-12">
									<p>{responseMessage}</p>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									window.location.reload();
								}}
							>
								Ok
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserDetails;
