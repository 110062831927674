const translations: any = {
	en: {
		navbar: {
			home: "Home",
			howitworks: "How it works",
			about: "About us",
			faq: "FAQ's",
			download: "Download",
		},
		hero: {
			description:
				"Revolutionizing QR codes for a personalized digital experience—seamless generation, customization, and sharing, shaping the future of effortless connectivity.",
			shopnow: "Buy T-Shirt",
		},
		howitworks: {
			title: "How it works",
			"step1-title": "Generate Dynamic Codes",
			"step1-description":
				"Welcome to the seamless universe of WCQ, where creating QR codes is not just a task; it's an experience. Immerse yourself in our sleek and user-friendly app, designed for a hassle-free QR code generation process. We've stripped away the complexities, leaving you with a simple and efficient tool to bring your ideas to life. And to kickstart your journey, we present you with a special welcome gift of 100 free credits upon installation – our way of saying thank you for choosing WCQ.",
			"step2-title": "Personalize Your Digital Canvas",
			"step2-description":
				"WCQ goes beyond static QR codes. With our app, you have the freedom to modify the destination link or update the associated content even after sharing the code. Need to redirect users to a different link? Want to showcase your latest social media updates? Easily update your QR code's information without needing to generate a new one. The control is yours, ensuring your QR code always reflects your latest content or message.",
			"step3-title": "Share, Print, and Own Your Personalized QR Code",
			"step3-description":
				"Once you've crafted the perfect QR code, it's time to put it into action. Share it digitally, print it on business cards or posters, and seamlessly integrate it into your marketing materials. WCQ ensures a smooth experience for sharing, making it easy for your audience to access your unique QR code. The codes you create are yours to keep, use, and customize forever, ensuring your digital identity remains as dynamic as you are.",
		},
		"about-us": {
			title: "About us",
			description:
				"At WCQ, we believe in the power of seamless communication. Our journey began with a simple yet profound goal: to redefine the QR code experience. Fueled by a passion for innovation, we envisioned a user-friendly platform that empowers individuals to effortlessly generate, share, and customize QR codes. We are driven by the desire to simplify connections in our increasingly digital world, making information accessible and personalization easy.",
			"shop-now": "Buy T-Shirt",
		},
		faq: {
			title: "FAQ's",
			question1: "How do I customize the content of my QR code with WCQ?",
			answer1:
				"After generating a QR code, navigate to the app's customization section where you can easily edit links, add social media profiles, or include personalized messages and images. The process is intuitive, allowing you to tailor the code to your preferences effortlessly.",
			question2:
				"Can I share my QR code generated with WCQ on social media?",
			answer2:
				"Absolutely! WCQ is designed for seamless sharing. Use the app to share your generated QR codes directly on various social media platforms, enhancing your online presence with just a few clicks.",
			question3: "What makes WCQ's dynamic QR codes different?",
			answer3:
				"WCQ's dynamic QR codes offer flexibility. You can change the linked content even after code creation. Whether it's updating a link, adding a new profile, or modifying a message, our codes adapt to your evolving needs.",
			question4: "How do I obtain more credits for generating QR codes",
			answer4:
				'WCQ provides a "buy credits" link within the app. You can easily purchase additional credits to continue generating, customizing, and sharing your QR codes.',
			question5: "Are the QR codes created with WCQ permanent?",
			answer5:
				"Yes, the QR codes generated with WCQ are yours to keep, use, and customize forever. Once created, your QR codes remain accessible for your convenience, allowing you to manage and update them whenever necessary.",
		},
		download: {
			title: "Download App",
			description:
				"Unlock the world of possibilities with WCQ – We Code Qr, the revolutionary app that empowers you to create, scan, and control dynamic QR codes effortlessly. Our intuitive and visually appealing interface ensures a seamless experience, while the ability to customize links, social media profiles, or personal messages adds a personal touch. As a welcome gift, enjoy 100 free credits upon creating an account. Generate, share, and even print your QR codes as PDFs, all for just 40 credits per code. Embrace endless creativity and convenience in the palm of your hand. Download WCQ now and redefine the way you share and connect!",
		},
	},
	ro: {
		navbar: {
			home: "Acasă",
			howitworks: "Cum funcționează",
			about: "Despre noi",
			faq: "Întrebări frecvente",
			download: "Descarcă",
		},
		hero: {
			description:
				"O revoluție în codurile QR pentru o experiență digitală personalizată - generare, customizare și partajare fără efort, definind viitorul conectivității.",
			shopnow: "Cumpără tricou",
		},
		howitworks: {
			title: "Cum funcționează",
			"step1-title": "Generează Coduri Dinamice",
			"step1-description":
				"Bine ai venit în universul WCQ, unde crearea de coduri QR nu este doar o sarcină; este o experiență. Scufundă-te în aplicația noastră elegantă și ușor de utilitaz, concepută pentru a-ți oferi un proces simplu și fără probleme de generare a codurilor QR. Am eliminat complexitățile, lăsându-te cu unealta perfectă pentru a da viață ideilor tale. Și pentru a începe călătoria ta, îți oferim un cadou special de bun venit: 100 de credite gratuite la instalare – modul nostru de a-ți mulțumi că ai ales WCQ.",
			"step2-title": "Personalizează-ți Codul QR",
			"step2-description":
				"WCQ depășește codurile QR statice. Cu aplicația noastră, ai libertatea de a modifica link-ul de destinație sau de a actualiza conținutul asociat chiar și după ce ai distribuit codul. Ai nevoie să redirecționezi utilizatorii către un alt link? Vrei să-ți arăți cele mai recente actualizări de pe rețelele sociale? Actualizează cu ușurință informațiile codului QR fără a fi nevoie să generezi unul nou. Controlul este al tău, asigurându-te că codul tău QR reflectă întotdeauna cel mai recent conținut sau mesaj.",
			"step3-title":
				"Distribuie, Imprimă și Ai Propriul Cod QR Personalizat",
			"step3-description":
				"Odată ce ai creat codul QR perfect, este timpul să-l pui în acțiune. Distribuie-l digital, imprima-l pe cărți de vizită sau postere și integrează-l fără probleme în materialele tale de marketing. WCQ asigură o experiență lină pentru distribuire, facilitând accesul publicului la codul tău QR unic. Codurile pe care le creezi îți aparțin pentru totdeauna, le poți folosi și personaliza în permanență, asigurându-te că identitatea ta digitală rămâne la fel de dinamică ca și tine.",
		},

		"about-us": {
			title: "Despre noi",
			description:
				"La WCQ, credem în puterea comunicării fără efort. Călătoria noastră a început cu un obiectiv simplu, dar profund: să redefinim experiența cu codurile QR. Stimulați de o pasiune pentru inovație, ne-am imaginat o platformă prietenoasă, ce împuternicește indivizii să genereze, distribuie și personalizeze coduri QR cu ușurință. Suntem ghidați de dorința de a simplifica conexiunile în lumea noastră digitală în continuă expansiune, facilitând accesul la informație și personalizarea.",
			"shop-now": "Cumpără tricou",
		},
		faq: {
			title: "Întrebări frecvente",
			question1: "Cum pot personaliza conținutul codului QR cu WCQ?",
			answer1:
				"După generarea unui cod QR, navighează către secțiunea de personalizare a aplicației, unde poți edita cu ușurință link-uri, adăuga profile de social media sau mesaje și imagini personalizate. Procesul este intuitiv, permițându-ți să ajustezi codul conform preferințelor tale fără efort.",
			question2: "Cum funcționează personalizarea codului QR?",
			answer2:
				"Cu WeCodeQr, poți personaliza ușor codul QR prin adăugarea de fotografii, text și link-uri în aplicație. Pur și simplu selectează designul tricoului pe care îl iubești, personalizează conținutul codului QR și fă-l unic.",
			question3: "Ce face ca codurile QR dinamice WCQ să fie diferite?",
			answer3:
				"Când scanezi un tricou WeCodeQr pentru prima dată, acesta se leagă automat la contul tău. Acest lucru asigură că ai control total asupra personalizării și gestionării codului QR al tricoului tău.",
			question4:
				"Cum obțin mai multe credite pentru generarea codurilor QR?",
			answer4:
				'WCQ oferă un link "cumpără credite" în cadrul aplicației. Poți achiziționa cu ușurință credite suplimentare pentru a continua să generezi, personalizezi și distribui codurile tale QR.',
			question5: "Sunt codurile QR create cu WCQ permanente?",
			answer5:
				"Da, codurile QR generate cu WCQ îți aparțin și le poți păstra, utiliza și personaliza pe viață. Odată create, codurile tale QR rămân accesibile pentru comoditatea ta, permițându-ți să le gestionezi și să le actualizezi oricând este necesar.",
		},
		download: {
			title: "Descarcă Aplicația",
			description:
				"Descoperă lumea posibilităților cu WCQ - We Code Qr, aplicația revoluționară ce îți oferă posibilitatea de a genera, scana și controla coduri QR dinamice, cu ușurință. Interfața noastră intuitivă și plăcută asigură o experiență fără probleme, în timp ce capacitatea de a personaliza link-uri, profiluri de social media sau mesaje adaugă o notă personală. Ca cadou de bun venit, beneficiezi de 100 de credite gratuite la crearea unui cont. Generează, distribuie și chiar printeaza codurile tale QR în format PDF, toate acestea pentru doar 40 de credite pe cod. Îmbrățișează creativitatea și comoditatea nelimitată în palma mâinii tale. Descarcă acum WCQ și redefinește modul în care partajezi și te conectezi!",
		},
	},
};

export default translations;
