/* eslint-disable react/jsx-pascal-case */
import React from "react";
import "./App.css";

import Notfound from "./components/Notfound/Notfound";
import QRinfo from "./components/QRinfo/QRinfo";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminInterface from "./components/Admin/AdminInterface";
import CookieConsent from "react-cookie-consent";
import MainLayout from "./components/layouts/MainLayout";
import CookiePolicy from "./components/Policies/CookiePolicy";
import TermsAndConditions from "./components/Policies/TermsAndConditions";
import Home from "./components/Home/Home";
import GDPR from "./components/Policies/GDPR";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import AdminLayout from "./components/layouts/AdminLayout";
import CodeDetails from "./components/Admin/CodeDetails/CodeDetails";
import CodeGeneration from "./components/Admin/CodeGeneration/CodeGeneration";
import TShirts from "./components/Admin/Dashboard/TShirts";
import TShirtDetails from "./components/Admin/TShirtDetails/TShirtDetails";
import Gallery from "./components/Admin/Gallery/Gallery";
import UsersDashboard from "./components/Admin/Dashboard/UsersDashboard";
import UserDetails from "./components/Admin/UserDetails/UserDetails";
import Orders from "./components/Admin/Orders/Orders";
import Order from "./components/Admin/Orders/Order";
import Livrare from "./components/Policies/Livrare";
import Returnare from "./components/Policies/Returnare";

class App extends React.Component {
	public render() {
		return (
			<>
				<CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
				<Router>
					<Routes>
						<Route element={<MainLayout />}>
							{/* route at the home page */}
							<Route path="/" element={<Home />} />

							{/* route at a specific id */}
							<Route path="/:id" element={<QRinfo />} />

							{/* route for admins */}
							<Route path="/application-backdoor" element={<AdminInterface />} />

							<Route element={<AdminLayout />}>
								<Route path="/application-backdoor/dashboard" element={<Dashboard />} />

								<Route path="/application-backdoor/users" element={<UsersDashboard />} />

								<Route path="/application-backdoor/user/:id" element={<UserDetails />} />

								{/* <Route
									path="/application-backdoor/tshirts"
									element={<TShirts />}
								/> */}

								<Route path="/application-backdoor/code" element={<AdminInterface />} />

								<Route path="/application-backdoor/code/:value" element={<CodeDetails />} />

								{/* <Route
									path="/application-backdoor/tshirt/:code"
									element={<TShirtDetails />}
								/> */}

								{/* <Route
									path="/application-backdoor/generate-codes"
									element={<CodeGeneration />}
								/> */}

								<Route path="/application-backdoor/gallery" element={<Gallery />} />

								<Route path="/application-backdoor/orders" element={<Orders />} />

								<Route path="/application-backdoor/orders/:id" element={<Order />} />
							</Route>

							{/* policies */}
							<Route path="/cookie-policy" element={<CookiePolicy />} />

							<Route path="/terms-and-conditions" element={<TermsAndConditions />} />

							<Route path="/livrare" element={<Livrare />} />
							<Route path="/returnare" element={<Returnare />} />

							<Route path="/gdpr" element={<GDPR />} />

							{/* Route at anything else */}
							<Route path="*" element={<Notfound />} />
						</Route>
					</Routes>
				</Router>
			</>
		);
	}
}

export default App;
