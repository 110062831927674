import { Translation } from "../../utils/translate";
import Navbar from "../QRinfo/navbar";

const Livrare = () => {
	return Translation.GetLanguage() === "ro" ? (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>Politica de Livrare pentru WecodeQR</h1>
				<h2>Descrierea Serviciului</h2>
				<p>
					WecodeQR oferă servicii de generare și afișare a codurilor QR în cadrul aplicației mobile. Nu există
					livrare fizică de produse, deoarece toate serviciile sunt furnizate digital prin intermediul
					aplicației.
				</p>

				<h2>Disponibilitatea Serviciului</h2>
				<p>
					Serviciul este disponibil 24/7 pentru utilizatorii aplicației. Utilizatorii pot accesa și utiliza
					aplicația în orice moment convenabil pentru ei.
				</p>

				<h2>Procesul de Achiziție și Livrare Virtuală</h2>
				<p>
					Utilizatorii pot achiziționa și genera coduri QR direct prin intermediul aplicației mobile. Procesul
					de achiziție și generare a codurilor QR este automat și se finalizează în timp real după confirmarea
					plății. Codurile QR sunt livrate virtual în cadrul aplicației, unde utilizatorii le pot accesa și
					utiliza imediat.
				</p>

				<h2>Plata și Confirmarea</h2>
				<p>
					Plata serviciilor se realizează în avans prin intermediul platformei de plată online integrate în
					aplicație. După confirmarea plății, utilizatorii primesc acces imediat la codurile QR generate în
					cadrul aplicației lor.
				</p>

				<h2>Asistență și Suport Tehnic</h2>
				<p>
					Echipa noastră de suport tehnic este disponibilă pentru a oferi asistență și răspunsuri la întrebări
					legate de utilizarea aplicației și generarea codurilor QR. Utilizatorii pot contacta echipa noastră
					prin intermediul secțiunii de suport din aplicație sau prin informațiile de contact disponibile pe
					site-ul nostru web.
				</p>

				<h2>Actualizări și Îmbunătățiri</h2>
				<p>
					Ne angajăm să îmbunătățim continuu serviciul nostru și să oferim actualizări și caracteristici noi
					în cadrul aplicației pentru a asigura cea mai bună experiență pentru utilizatori.
				</p>
			</div>
		</>
	) : (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>Delivery Policy for WecodeQR</h1>
				<h2>Service Description</h2>
				<p>
					WecodeQR offers services for generating and displaying QR codes within the mobile application. There
					is no physical delivery of products, as all services are provided digitally through the application.
				</p>

				<h2>Service Availability</h2>
				<p>
					The service is available 24/7 for application users. Users can access and use the application at any
					convenient time for them.
				</p>

				<h2>Purchase and Virtual Delivery Process</h2>
				<p>
					Users can purchase and generate QR codes directly through the mobile application. The process of
					purchasing and generating QR codes is automated and completes in real-time after payment
					confirmation. QR codes are delivered virtually within the application, where users can access and
					use them immediately.
				</p>

				<h2>Payment and Confirmation</h2>
				<p>
					Payment for services is made in advance through the online payment platform integrated into the
					application. Upon payment confirmation, users are granted immediate access to the QR codes generated
					within their application.
				</p>

				<h2>Assistance and Technical Support</h2>
				<p>
					Our technical support team is available to provide assistance and answers to questions related to
					the use of the application and QR code generation. Users can contact our team through the support
					section of the application or through the contact information available on our website.
				</p>

				<h2>Updates and Improvements</h2>
				<p>
					We are committed to continuously improving our service and providing updates and new features within
					the application to ensure the best experience for users.
				</p>
			</div>
		</>
	);
};

export default Livrare;
