import { Outlet } from "react-router";
import "./MainLayout.scss";
import { Link } from "react-router-dom";

import NTPLogo from "ntp-logo-react";

const MainLayout = () => {
	return (
		<>
			<Outlet />

			<footer className="w-100 mb-5 pt-5 mt-auto pb-5">
				<div className="container">
					<div className="row align-items-center ">
						<div className="col-6 col-md-3">
							<Link to="/" className="copyright">
								© 2024 - WeCodeQR
							</Link>

							<p>
								<br />
								S.C. WYSAPP SMARTWEAR S.R.L
								<br />
								CUI: 47715141
								<br />
								Nr: Registrul Comertului: J23/1321/2023
								<br />
								Adresa: Judetul Ilfov, Oras Voluntari, Sos. Bucuresti Nord, nr. 10B, Corp B1, Sc. C, Et.
								3, AP. 388
							</p>
						</div>
						<div className="col-6 col-md-4 d-flex flex-column align-items-start links">
							<Link to="/cookie-policy">Cookie Policy</Link>
							<Link to="/terms-and-conditions">Terms and Conditions</Link>

							<Link to="/gdpr">GDPR</Link>
							<Link to="/livrare">Livrare</Link>
							<Link to="/returnare">Returnare si Anulare</Link>
						</div>
						<div className="col-12 col-md-5 row">
							<NTPLogo color="#ffffff" version="vertical" secret="137980" />
							<a
								href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
								target="_blank"
							>
								<img src="anpc-sol.avif" alt="" className="ntp-logo" />
							</a>

							<a href="https://anpc.ro/ce-este-sal/" target="_blank">
								<img src="anpc-sal.avif" alt="" className="ntp-logo" />
							</a>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default MainLayout;
