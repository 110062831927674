// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-image-short {
  max-width: 100%;
  float: left;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .about-image-short {
    max-width: 100%;
    max-height: none !important;
    height: 14rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Home/sections/About.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,WAAA;EAEA,mBAAA;AAAD;AAEC;EAND;IAOE,eAAA;IACA,2BAAA;IACA,wBAAA;EACA;AACF","sourcesContent":[".about-image-short {\n\tmax-width: 100%;\n\tfloat: left;\n\t// max-height: 210px;\n\tborder-radius: 10px;\n\n\t@media (max-width: 991px) {\n\t\tmax-width: 100%;\n\t\tmax-height: none !important;\n\t\theight: 14rem !important;\n\t}\n}\n\n// .about-image-long {\n// \tmax-width: 100%;\n// \tfloat: left;\n// \tmax-height: 210px;\n// \tborder-radius: 10px;\n// \tobject-fit: cover;\n// \twidth: 100%;\n// \tobject-position: top;\n\n// \t@media (max-width: 991px) {\n// \t\tdisplay: none;\n// \t}\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
